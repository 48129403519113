/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import HistoryIcon from '@material-ui/icons/History';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'UI/button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Timeline from 'rsuite/Timeline';
import BarLoader from 'react-spinners/BarLoader';
import axios from 'axios';
import moment from 'moment';
import { baseURL } from 'utils/apiEndpoints';
import 'rsuite/dist/rsuite.min.css';

const TypeMappingText = {
  18: 'Unreachable',
  17: 'Follow Up',
  31: 'Trip Deferred',
};

const FollowupHistory = ({ classes, requestId }) => {
  const [showHistory, toggleHistory] = useState(false);
  const [loader, toggleLoader] = useState(true);
  const [reminders, setReminders] = useState([]);

  const onShowHistory = () => {
    toggleHistory(true);
    toggleLoader(true);

    axios(`${baseURL}request/${requestId}/reminders`).then((res) => {
      toggleLoader(false);
      setReminders(res.data.reminders);
    }).catch(() => {
      toggleLoader(false);
      setReminders([]);
    });
  };

  const closeDialog = () => {
    toggleHistory(false);
  };

  return (
    <>
      <Button
        variant="plain"
        className={classes.createItnBtn}
        onClick={onShowHistory}
      >
        <HistoryIcon className={classes.historyIcon} />
        Follow up history
      </Button>
      <Dialog open={showHistory} onClose={closeDialog}>
        <div className={classes.dialogContainer}>
          <div className={classes.dialogHeader}>
            <Typography variant="subtitle1"><b>Follow-up History</b></Typography>
            <div onClick={closeDialog} className={classes.closeIcon}>
              <CloseIcon />
            </div>
          </div>
          <div className={classes.dialogContent}>
            {
              loader ? (
                <div className={classes.contentCenter}>
                  <BarLoader color="#36d7b7" width={250} />
                </div>
              ) : (
                <>
                  {
                    reminders.length ? (
                      <>
                        {
                          reminders.map((historyItem) => (
                            <Timeline>
                              <Timeline.Item>
                                <Typography variant="body1">
                                  <b>Scheduled At: </b>{moment(historyItem.scheduledAt).format('DD MMM YY hh:mm A')}
                                </Typography>
                                {
                                  historyItem.type && historyItem.type.first ? (
                                    <Typography variant="body2" fontWeight={500}>
                                      <b>Type: </b>{TypeMappingText[historyItem.type.first] || `Unknown Type ${historyItem.type.first}`}
                                    </Typography>
                                  ) : null
                                }
                                {
                                  historyItem.note ? (
                                    <Typography variant="body2">
                                      <b>Note: </b>{historyItem.note}
                                    </Typography>
                                  ) : null
                                }
                                <Typography variant="caption" paddingTop={4}>
                                  <b>Created On: </b>{moment(historyItem.created_at).format('DD MMM YY hh:mm A')}
                                </Typography>
                                <Typography paddingBottom={12}>&nbsp;</Typography>
                              </Timeline.Item>
                            </Timeline>
                          ))
                        }
                      </>
                    ) : (
                      <div className={classes.contentCenter}>
                        <Typography variant="subtitle1">No follow up history was found.</Typography>
                      </div>
                    )
                  }
                </>
              )
            }
          </div>
        </div>
      </Dialog>
    </>
  );
};

const styles = (theme) => ({
  createItnBtn: {
    minWidth: 140,
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  historyIcon: {
    width: 16,
    height: 16,
    marginRight: 6,
  },
  dialogContainer: {
    width: '400px',
    background: '#FFFFFF',
    borderRadius: '6px',
    maxHeight: '90vh',
    overflow: 'scroll',
  },
  dialogHeader: {
    width: '100%',
    background: '#EEF4F9',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 20,
  },
  contentCenter: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 16,
    justifyContent: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
  },
});

FollowupHistory.propTypes = {
  classes: PropTypes.object,
  requestId: PropTypes.string,
};

export default withStyles(styles)(FollowupHistory);
