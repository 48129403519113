import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import RequestCard from 'components/dashboard/requestCard';
import RequestDetails from 'components/dashboard/requestDetails';
import AddRequirement from 'components/dashboard/addRequirement';
import Unreachable from 'components/dashboard/unreachable';
import Filters from 'components/dashboard/filters';
import EditRequest from 'components/dashboard/editRequest';
import Confirmation from 'UI/confirmation';
import LostReason from 'components/dashboard/lostReason';
import InvalidRequest from 'components/dashboard/invalidRequest';
import FollowUpRequest from 'components/dashboard/followupReason';
import Upload from 'components/dashboard/upload';
import SendItinerary from 'components/dashboard/sendItinerary';
import AddWhatsApp from 'components/dashboard/addWhatsApp';
import ItineraryDetails from 'components/dashboard/itineraryDetails';
import UpdateTripDetails from 'components/dashboard/updateTripRequirements';
import OrderModification from 'components/dashboard/orderModification';
import MarkPayment from 'components/dashboard/markPayment';
import CancelBooking from 'components/dashboard/cancelBooking';
import create from 'assets/svg/create.svg';
import filterImg from 'assets/svg/filter.svg';
import search from 'assets/svg/search.svg';
import Refresh from 'assets/icons/refresh';
import Close from 'assets/icons/close';
import noResultImg from 'assets/png/no_result.png';
// import emptyNewAttentionImg from 'assets/png/empty_needs_attention.png';
// import emptyLiveImg from 'assets/png/empty_live.png';
import emptyAttentionImg from 'assets/png/empty_attention.png';
import Autocomplete from 'UI/autocomplete';
import EmptyScreen from 'UI/emptyScreen';
import Loader from 'UI/loader';
import { colors } from 'UI/styleConsts';
import TripDeferred from 'components/dashboard/tripDeferred';
import { ITINERARY_READ_MODES, ROLES } from 'utils/consts';
import {
  convertRequirement,
  debounce,
  deepClone,
  headerGenerator,
  isEqual,
  parseQueries,
  processRequirement,
} from 'utils/common';
import ConfirmRequirement from 'components/dashboard/confirmRequirement';
import CustomerBankInfo from 'components/dashboard/customerBankInfo';
import TransactionBooking from 'components/dashboard/transactionBooking';
import Revival from 'components/dashboard/revival';
import CancelBookingRequest from 'components/dashboard/cancelBookingRequest';
import AddActualSupplier from 'components/dashboard/addActualSupplier';
import Travellers from 'components/itineraryMaker/travellers';
import PanDetails from 'components/itineraryMaker/panDetails';
import Header from 'UI/header';
import uploadImg from 'assets/svg/upload.svg';
import arrowBack from 'assets/svg/arrow_left.svg';
import { getRequest, postRequest } from 'utils/apiRequests';
import { baseURL } from 'utils/apiEndpoints';
import CustomerVerification from 'components/itineraryMaker/customerVerification';
import ItineraryTemplate from 'components/dashboard/itineraryTemplate';
import LabelInput from 'UI/labelInput';
import Send from '@material-ui/icons/Send';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import CloneIcon from '@material-ui/icons/FileCopyOutlined';
import Notifications from '../notification/notificationRedux';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    let parsedFilters = {};
    this.mode = 'dashboard';
    const pathSplits = window.location.pathname.split('/');
    if (pathSplits.length === 3 && pathSplits[1] === 'request') {
      this.mode = 'focused';
      this.usId = pathSplits[2];
    } else {
      const savedFilters = window.localStorage.getItem('filters');
      if (savedFilters !== null) {
        parsedFilters = JSON.parse(savedFilters);
      }
    }
    this.notificationsRef = React.createRef();
    this.state = {
      skip: 0,
      filterDrawer: false,
      editRequestDrawer: false,
      filters: parsedFilters,
      activeRequest: {},
      activeRequestAction: '',
      activeTab: 'attention',
      ownerVal: '',
      ownerItem: {},
      requirementDialog: false,
      markUnreachableDialog: false,
      markInvalidDialog: false,
      searchQuery: '',
      requestSelections: {},
      dismissDialog: false,
      voucherDialog: false,
      sendToRequestBankDialog: false,
      custPanDialog: false,
      verifyDocumentDialog: false,
      markLostDialog: false,
      invalidDialog: false,
      uploadDialog: false,
      editDetailsDrawer: false,
      followUpDialog: false,
      sendItineraryDialog: false,
      addWhatsAppDialog: false,
      tripDeferredDialog: false,
      undeferDialog: false,
      itineraryDetailsDialog: false,
      editRequirementDialog: false,
      qualifyRequestDialog: false,
      orderModificationDialog: false,
      acceptPaymentDialog: false,
      cancelRequestDialog: false,
      cancelItineraryDialog: false,
      refundPaymentDialog: false,
      refundFromWalletDialog: false,
      newRefundPaymentDialog: false,
      confirmRequirementDialog: false,
      cancelBookingDialog: false,
      transactionDialog: false,
      customerBankInfoDialog: false,
      addActualSupplierDialog: false,
      itineraryActionType: '',
      activeSupplier: {},
      showFollowUp: false,
      reviveDialog: false,
      itineraryTemplateDialog: false,
      itineraryTemplateDialogItinerary: null,
      loaderDialog: false,
      travellerDetails: {},
    };
    this.activePopup = '';
    this.activeDialog = '';
    this.isUploadTouhed = false;
    this.isAddRequestTouched = false;
    this.activeItinerary = null;
    this.firstSearchCall = true;
    const { expert } = this.props;
    this.allowedReassignment = expert.roles.includes(ROLES.ADMIN)
      || expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.FILTRATION_TEAM_LEAD)
      || (expert.roles.includes(ROLES.FILTRATION));
  }

  // eslint-disable-next-line
  componentDidMount = () => {
    const { getRequestFilters, token } = this.props;
    const { filters } = this.state;
    this.headers = headerGenerator(token);
    if (Object.keys(filters).length) {
      this.applyFilterParams();
    } else {
      this.refreshHandler({}, 'replace');
    }
    this.showFollouUpDialog = false;
    const parsedQueries = parseQueries(window.location.href);
    if (parsedQueries.followup === 'true') {
      this.showFollouUpDialog = true;
    }
    getRequestFilters({}, this.headers);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      requestList,
      requestById,
      isFetchingRequests,
      addRequestResp,
      addRequestError,
      addRequestErrorMsg,
      updateRequestDetailsResp,
      updateRequestDetailsError,
      updateRequestDetailsErrorMsg,
      reAssignRequestResp,
      reAssignRequestError,
      reAssignRequestErrorMsg,
      updateStatusResp,
      updateStatusError,
      updateStatusErrorMsg,
      deleteItineraryResp,
      deleteItineraryError,
      deleteItineraryErrorMsg,
      updateRemarkResp,
      updateRemarkError,
      updateRemarkErrorMsg,
      showSnackbar,
      resetDashboardResp,
      requestSearchResp,
      updateOrderResp,
      updateOrderError,
      updateOrderErrorMsg,
      modifyInstallmentsResp,
      modifyInstallmentsError,
      modifyInstallmentsErrorMsg,
      bookingActionResp,
      bookingActionError,
      bookingActionErrorMsg,
      acceptPaymentResp,
      acceptPaymentError,
      acceptPaymentErrorMsg,
      requestListError,
      requestListErrorMsg,
      requestSearchError,
      requestSearchErrorMsg,
      getRequestFiltersError,
      getRequestFiltersErrorMsg,
      resendConfirmationResp,
      resendConfirmationError,
      resendConfirmationErrorMsg,
      generateVoucherResp,
      generateVoucherError,
      generateVoucherErrorMsg,
      refundPaymentResp,
      refundPaymentError,
      refundPaymentErrorMsg,
      cancelItineraryResp,
      cancelItineraryError,
      cancelItineraryErrorMsg,
      refundFromWalletResp,
      refundFromWalletError,
      refundFromWalletErrorMsg,
      refundToWalletResp,
      refundToWalletError,
      refundToWalletErrorMsg,
      saveItineraryDraftResp,
      saveItineraryDraftError,
      saveItineraryDraftErrorMsg,
      markBookingTransactionResp,
      markBookingTransactionError,
      markBookingTransactionErrorMsg,
      cancelBookingResp,
      cancelBookingError,
      cancelBookingErrorMsg,
      getBookingsError,
      getBookingsErrorMsg,
      getB2bWalletBalanceError,
      getB2bWalletBalanceErrorMsg,
      getRefundDataError,
      getRefundDataErrorMsg,
      getVirtualAccountDataError,
      getVirtualAccountDataErrorMsg,
      isGeneratingVoucher,
      getRequestByID,
    } = this.props;
    const { isGettingActiveRequest } = this.state;
    const { searchQuery, activeRequest, activeRequestAction } = this.state;
    if (!isEqual(prevProps.requestListError, requestListError) && requestListError) {
      showSnackbar(requestListErrorMsg, 'error');
    }
    if (this.mode === 'focused' && (requestList.length || !_.isEmpty(requestById))
      && this.showFollouUpDialog) {
      this.showFollouUpDialog = false;
      this.activeDialog = 'followUpDialog';
      this.setState({
        followUpDialog: true,
        activeRequestAction: 'FOLLOW_UP',
      });
    }
    if (!isEqual(prevProps.requestSearchError, requestSearchError) && requestSearchError) {
      showSnackbar(requestSearchErrorMsg, 'error');
    }
    if (!isEqual(prevProps.getB2bWalletBalanceError, getB2bWalletBalanceError)
      && getB2bWalletBalanceError) {
      showSnackbar(getB2bWalletBalanceErrorMsg || 'Error getting partner wallet balance', 'error');
    }
    if (!isEqual(prevProps.getRefundDataError, getRefundDataError)
      && getRefundDataError) {
      showSnackbar(getRefundDataErrorMsg || 'Error getting refund data', 'error');
    }
    if (!isEqual(prevProps.getVirtualAccountDataError, getVirtualAccountDataError)
      && getVirtualAccountDataError) {
      showSnackbar(getVirtualAccountDataErrorMsg || 'Error getting account data', 'error');
    }
    if (!isEqual(prevProps.getBookingsError, getBookingsError) && getBookingsError) {
      showSnackbar(getBookingsErrorMsg, 'error');
    }
    if (!isEqual(prevProps.getRequestFiltersError, getRequestFiltersError)
      && getRequestFiltersError) {
      showSnackbar(getRequestFiltersErrorMsg, 'error');
    }
    if (!isEqual(markBookingTransactionResp, prevProps.markBookingTransactionResp)
      && markBookingTransactionResp.message) {
      showSnackbar(markBookingTransactionResp.message || 'Transaction recorded successfully', 'success');
      this.setState({ transactionDialog: false });
    } else if (!isEqual(markBookingTransactionError, prevProps.markBookingTransactionError)
      && markBookingTransactionError) {
      showSnackbar(markBookingTransactionErrorMsg, 'error');
    }
    if (!isEqual(refundPaymentResp, prevProps.refundPaymentResp) && refundPaymentResp.message) {
      showSnackbar(refundPaymentResp.message, 'success');
      this.setState({ refundPaymentDialog: false });
    } else if (!isEqual(refundPaymentError, prevProps.refundPaymentError) && refundPaymentError) {
      showSnackbar(refundPaymentErrorMsg, 'error');
    }
    if (!isEqual(refundFromWalletResp, prevProps.refundFromWalletResp)
      && refundFromWalletResp.message) {
      showSnackbar(refundFromWalletResp.message, 'success');
      this.setState({ refundFromWalletDialog: false });
    } else if (!isEqual(refundFromWalletError, prevProps.refundFromWalletError)
      && refundFromWalletError) {
      showSnackbar(refundFromWalletErrorMsg, 'error');
    }
    if (!isEqual(cancelItineraryResp, prevProps.cancelItineraryResp)
      && cancelItineraryResp.message) {
      showSnackbar(cancelItineraryResp.message, 'success');
      this.setState({ cancelItineraryDialog: false });
    } else if (!isEqual(cancelItineraryError, prevProps.cancelItineraryError)
      && cancelItineraryError) {
      showSnackbar(cancelItineraryErrorMsg, 'error');
    }
    if (!isEqual(acceptPaymentResp, prevProps.acceptPaymentResp) && acceptPaymentResp.message) {
      showSnackbar(acceptPaymentResp.message, 'success');
      this.setState({ acceptPaymentDialog: false });
    } else if (!isEqual(acceptPaymentError, prevProps.acceptPaymentError) && acceptPaymentError) {
      showSnackbar(acceptPaymentErrorMsg, 'error');
    }
    if (!isEqual(resendConfirmationResp, prevProps.resendConfirmationResp)
      && resendConfirmationResp.message) {
      resetDashboardResp();
      showSnackbar(resendConfirmationResp.message, 'success');
    } else if (!isEqual(resendConfirmationError, prevProps.resendConfirmationError)
      && resendConfirmationError) {
      resetDashboardResp();
      showSnackbar(resendConfirmationErrorMsg, 'error');
    }
    if (!isEqual(bookingActionResp, prevProps.bookingActionResp) && bookingActionResp.request) {
      showSnackbar(bookingActionResp.message || 'Booking updated successfully', 'success');
      this.setState({ itineraryDetailsDialog: false });
      this.getBookings();
    } else if (!isEqual(bookingActionError, prevProps.bookingActionError) && bookingActionError) {
      showSnackbar(bookingActionErrorMsg, 'error');
    }
    if (!isEqual(updateOrderResp, prevProps.updateOrderResp) && updateOrderResp.message) {
      showSnackbar(updateOrderResp.message, 'success');
    } else if (!isEqual(updateOrderError, prevProps.updateOrderError) && updateOrderError) {
      showSnackbar(updateOrderErrorMsg, 'error');
    }
    if (!isEqual(modifyInstallmentsResp, prevProps.modifyInstallmentsResp) && modifyInstallmentsResp.message) {
      showSnackbar(modifyInstallmentsResp.message, 'success');
    } else if (!isEqual(modifyInstallmentsError, prevProps.modifyInstallmentsError) && modifyInstallmentsError) {
      showSnackbar(modifyInstallmentsErrorMsg, 'error');
    }
    if (!isEqual(refundToWalletResp, prevProps.refundToWalletResp) && refundToWalletResp.message) {
      showSnackbar(refundToWalletResp.message, 'success');
    } else if (!isEqual(refundToWalletError, prevProps.refundToWalletError)
      && refundToWalletError) {
      showSnackbar(refundToWalletErrorMsg, 'error');
    }
    if (!isEqual(generateVoucherResp, prevProps.generateVoucherResp)
      && generateVoucherResp.message) {
      this.setState({ itineraryDetailsDialog: false });
      showSnackbar(generateVoucherResp.message, 'success');
    } else if (!isEqual(generateVoucherError, prevProps.generateVoucherError)
      && generateVoucherError) {
      showSnackbar(generateVoucherErrorMsg, 'error');
    }
    if (!isEqual(addRequestResp, prevProps.addRequestResp) && addRequestResp._id) {
      showSnackbar(addRequestResp.message, 'success');
      console.log('activeRequest 1');
      this.setState({
        searchQuery: '',
        activeRequest: requestList[0],
        // activeIndex: 0,
        requirementDialog: false,
      });
      return;
    }
    if (!isEqual(addRequestError, prevProps.addRequestError) && addRequestError) {
      showSnackbar(addRequestErrorMsg, 'error');
      resetDashboardResp();
    }
    if (!isEqual(updateStatusError, prevProps.updateStatusError) && updateStatusError) {
      resetDashboardResp();
      showSnackbar(updateStatusErrorMsg, 'error');
    } else if (!isEqual(updateStatusResp, prevProps.updateStatusResp) && updateStatusResp.message) {
      // const { attention } = updateStatusResp.request;
      const changes = {};
      if (searchQuery.length && requestSearchResp.length) {
        const activeIndex = requestSearchResp
          .findIndex((req) => req._id === updateStatusResp.request._id);
        if (activeIndex !== -1) {
          console.log('activeRequest 2');
          changes.activeRequest = requestSearchResp[activeIndex];
        } else {
          console.log('activeRequest 3');
          // request has been marked as invalid or lost hence would have removed from list
          changes.activeRequest = requestSearchResp[0];
        }
      } else {
        const activeIndex = requestList
          .findIndex((req) => req._id === updateStatusResp.request._id);
        if (activeIndex !== -1) {
          console.log('activeRequest 4');
          changes.activeRequest = requestList[activeIndex];
        } else {
          console.log('activeRequest 5');
          changes.activeRequest = requestList[0] || updateStatusResp.request;
        }
      }
      this.setState({
        ...changes,
        itineraryDetailsDialog: false,
        sendItineraryDialog: false,
        cancelRequestDialog: false,
        // activeTab: attention < 0 ? 'live' : 'attention',
      });
      resetDashboardResp();
      showSnackbar(updateStatusResp.message, 'success');
      if (activeRequestAction === 'SEND_TO_REQUEST_BANK') {
        this.applyFilterParams();
        if (searchQuery.length) {
          this.searchRequests(searchQuery);
        }
        // this.resetFilter();
      } else {
        getRequestByID({ usId: activeRequest.usId }, this.headers);
      }
      return;
    }
    if (!isEqual(updateRequestDetailsError, prevProps.updateRequestDetailsError)
      && updateRequestDetailsError) {
      showSnackbar(updateRequestDetailsErrorMsg, 'error');
    } else if (!isEqual(updateRequestDetailsResp, prevProps.updateRequestDetailsResp)
      && updateRequestDetailsResp.state) {
      showSnackbar(updateRequestDetailsResp.message, 'success');
      console.log('activeRequest 6');
      this.setState({
        activeRequest: { ...updateRequestDetailsResp.request[0] },
        editRequestDrawer: false,
        addWhatsAppDialog: false,
        editRequirementDialog: false,
        // confirmRequirementDialog: false,
      });
      // if (activeRequestAction === 'CONFIRM_REQUIREMENT') {
      //   window.open(`/itineraryMaker/request/${activeRequest.usId}`, '_blank');
      // }
      return;
    }
    if (!isEqual(saveItineraryDraftError, prevProps.saveItineraryDraftError)
      && saveItineraryDraftError) {
      showSnackbar(saveItineraryDraftErrorMsg, 'error');
    } else if (!isEqual(saveItineraryDraftResp, prevProps.saveItineraryDraftResp)
      && saveItineraryDraftResp.message) {
      this.setState({ confirmRequirementDialog: false });
      const { request: { draftedItineraryId } } = saveItineraryDraftResp;
      window.open(`/itineraryMaker/request/${activeRequest.usId}/${draftedItineraryId}`, '_blank');
    }
    if (!isEqual(reAssignRequestError, prevProps.reAssignRequestError)
      && reAssignRequestError) {
      showSnackbar(reAssignRequestErrorMsg, 'error');
    } else if (!isEqual(reAssignRequestResp, prevProps.reAssignRequestResp)
      && reAssignRequestResp.state) {
      showSnackbar(reAssignRequestResp.message, 'success');
      this.setState({
        requestSelections: {},
        ownerItem: {},
        ownerVal: '',
      });
      if (searchQuery.length) {
        this.searchRequests(searchQuery);
      }
      // this.resetFilter();
      return;
    }
    if (!isEqual(deleteItineraryResp, prevProps.deleteItineraryResp)
      && deleteItineraryResp.message) {
      showSnackbar(deleteItineraryResp.message, 'success');
    } else if (!isEqual(deleteItineraryError, prevProps.deleteItineraryError)
      && deleteItineraryError) {
      showSnackbar(deleteItineraryErrorMsg, 'error');
    }
    if (!isEqual(cancelBookingResp, prevProps.cancelBookingResp)
      && cancelBookingResp && Object.keys(cancelBookingResp).length) {
      showSnackbar(cancelBookingResp.message, 'success');
      this.setState({ cancelBookingDialog: false });
      this.getBookings();
    } else if (!isEqual(cancelBookingError, prevProps.cancelBookingError)
      && cancelBookingError) {
      showSnackbar(cancelBookingErrorMsg, 'error');
    }
    if (!isEqual(updateRemarkResp, prevProps.updateRemarkResp) && updateRemarkResp.message) {
      showSnackbar(updateRemarkResp.message, 'success');
    } else if (!isEqual(updateRemarkError, prevProps.updateRemarkError) && updateRemarkError) {
      showSnackbar(updateRemarkErrorMsg, 'error');
    }
    if (searchQuery.length && prevState.searchQuery.length === 0) {
      this.firstSearchCall = false;
    } else if (prevState.searchQuery.length && searchQuery.length === 0) {
      this.firstSearchCall = true;
    }
    if (!isEqual(requestSearchResp, prevProps.requestSearchResp)
      && !_.isEmpty(requestSearchResp) && !_.isEmpty(searchQuery)) {
      console.log('activeRequest 6.5');
      await getRequestByID({ usId: requestSearchResp[0]?.usId }, this.headers);
      await this.setState({
        activeRequest: requestSearchResp[0] || {},
        isGettingActiveRequest: true,
      });
    } else if ((!isEqual(requestList, prevProps.requestList)
      || !isEqual(searchQuery, prevState.searchQuery))
      && requestList.length && searchQuery.length === 0) {
      const requestIndex = requestList.findIndex((r) => r._id === activeRequest._id);
      if (requestIndex === -1) {
        console.log('activeRequest 7');
        if (!_.isNil(requestList[0]?.usId)) {
          await getRequestByID({ usId: requestList[0]?.usId }, this.headers);
          await this.setState({
            activeRequest: requestList[0],
            isGettingActiveRequest: true,
          });
        }
      } else {
        console.log('activeRequest 8');
        this.setState({ activeRequest: requestList[requestIndex] || {} });
      }
    }
    if (!isGeneratingVoucher && prevProps.isGeneratingVoucher) {
      this.setState({ voucherDialog: false, custPanDialog: false, loaderDialog: false });
    }

    // const latestRequest = (_.isEmpty(searchQuery) ? requestList : requestSearchResp)?.find((r) => r._id === activeRequest._id);
    const activeRequestFetched = !_.isEqual(requestById, activeRequest)
      || (isGettingActiveRequest && prevProps.isFetchingRequests && !isFetchingRequests);
    // console.log(`${_.isEqual(latestRequest, activeRequest)} activeRequestFetched ${activeRequestFetched} isGettingActiveRequest ${isGettingActiveRequest} latestRequest ${latestRequest?._id} ${latestRequest?.itineraries?.map((i) => i.identifier)} activeRequest ${activeRequest?._id} ${activeRequest?.itineraries?.map((i) => i.identifier)}`);
    // console.log('latestRequest ', latestRequest);
    // console.log('activeRequest ', activeRequest);
    console.log('isGettingActiveRequest ', isGettingActiveRequest, prevProps.isFetchingRequests, !isFetchingRequests);
    if (requestById && activeRequest && activeRequestFetched) {
      console.log('activeRequest 9');
      this.setState({
        activeRequest: requestById,
        isGettingActiveRequest: false,
      });
    }
  };

  // getRequestCount = (requests = []) => {
  //   let attention = 0;
  //   let live = 0;
  //   for (let i = 0; i < requests.length; i++) {
  //     if (requests[i].attention < 0) {
  //       live++;
  //     } else {
  //       attention++;
  //     }
  //   }
  //   return {
  //     attention,
  //     live,
  //   };
  // };

  addRequest = (params) => {
    const { expert, addRequest } = this.props;
    addRequest({
      params,
      isReAssigning: expert.roles.includes(ROLES.FILTRATION),
    }, this.headers);
  };

  toggleState = (field) => {
    this.activeDialog = field;
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  // tabChangeHandler = (tab) => {
  //   const { activeTab } = this.state;
  //   if (tab !== activeTab) {
  //     this.setState({ activeTab: tab });
  //   }
  // };

  refreshHandler = (filters = {}, kind) => {
    const { getRequestList, getRequestByID } = this.props;
    if (this.mode === 'focused') {
      getRequestByID({ usId: this.usId }, this.headers);
      return;
    }
    const { skip } = this.state;
    const requestParams = {
      filters: { ...filters },
      skip,
      limit: 15,
      kind,
    };
    getRequestList(requestParams, this.headers);
  };

  requestScrollHandler = debounce((target, scrollTop) => {
    const { isFetchingRequests } = this.props;
    const { skip } = this.state;
    if (!isFetchingRequests && ((scrollTop >= ((skip / 15) + 0.25) * target.clientHeight))) {
      this.setState((prevState) => ({ skip: prevState.skip + 15 }),
        () => this.applyFilterParams('append'));
    }
  }, 500, true);

  onSearch = (query) => {
    if (this.firstSearchCall) {
      this.firstSearchCall = false;
      this.resetFilter();
    }
    this.setState({
      searchQuery: query,
      requestSelections: {},
    });
    _.debounce(() => this.searchRequests(query), 500)();
  };

  updatePaymentAmount = (paymentAmount) => {
    if (Number(paymentAmount) > 100000) {
      const { showSnackbar } = this.props;
      showSnackbar('Break into small payments of upto Rs 1L', 'error');
      return;
    }
    this.setState({
      paymentAmount,
      paymentLink: null,
    });
  };

  clearSearchQuery = () => {
    const { requestList = [] } = this.props;
    this.setState({
      searchQuery: '',
      activeRequest: requestList[0],
      // activeIndex: 0,
    });
  };

  // eslint-disable-next-line
  searchRequests = debounce((query) => {
    const { requestSearch, version } = this.props;
    const params = {
      skip: 0,
      limit: 15,
      query,
    };
    requestSearch(params, this.headers);
    if (query.length === 0 && version > Number(process.env.REACT_APP_VERSION)) {
      window.location.reload();
    }
  }, 500, true);

  getBookings = () => {
    const { getBookings } = this.props;
    const { activeRequest } = this.state;
    getBookings({ requestId: activeRequest._id }, this.headers);
  };

  getRefundData = () => {
    const { getRefundData } = this.props;
    const { activeRequest } = this.state;
    if (!activeRequest.orderInfo.orderData) return;
    getRefundData({
      orderId: activeRequest.orderInfo.orderData[0].orderId,
      productId: activeRequest._id,
    }, this.headers);
    if (activeRequest.b2bPartner) {
      const { getB2bWalletBalance } = this.props;
      getB2bWalletBalance({ partnerId: activeRequest.b2bPartner }, this.headers);
    }
  };

  getVirtualAccountData = () => {
    const { getVirtualAccountData } = this.props;
    const { activeRequest } = this.state;
    getVirtualAccountData({
      requestId: activeRequest._id,
    }, this.headers);
  };

  actionHandler = (action) => {
    const { getRequestFilters, version } = this.props;
    if (action === 'refresh') {
      if (version > Number(process.env.REACT_APP_VERSION)) {
        location.reload(true);  // eslint-disable-line
      } else {
        this.setState({ skip: 0 }, () => this.applyFilterParams());
      }
    } else if (action === 'requirement') {
      this.toggleState('requirementDialog');
    } else if (action === 'filter') {
      const selectedFilters = this.processFiltersToApply();
      selectedFilters.status = [];
      getRequestFilters({ selectedFilters }, this.headers);
      this.toggleState('filterDrawer');
    }
  };

  cardActionHandler = async (e, action, request, index) => {
    const {
      requestList, requestSearchResp, starRequest, interestedRequest, getRequestByID,
    } = this.props;
    const { requestSelections, searchQuery } = this.state;
    const { _id } = request;
    const selected = e.target.checked;
    if (action === 'assignment') {
      const modSelection = { ...requestSelections };
      if (selected) {
        modSelection[_id] = request;
      } else {
        delete modSelection[_id];
      }
      this.setState({ requestSelections: modSelection });
    } else if (action === 'pin') {
      const searchUpdate = Boolean(searchQuery.length && requestSearchResp.length);
      const params = {
        requestId: _id,
        starred: Boolean(selected),
        searchUpdate,
      };
      starRequest(params, this.headers);
    } else if (action === 'interested') {
      const searchUpdate = Boolean(searchQuery.length && requestSearchResp.length);
      const params = {
        requestId: _id,
        interested: Boolean(selected),
        searchUpdate,
      };
      interestedRequest(params, this.headers);
    } else if (e.target.tagName !== 'INPUT' && action === 'set_active') {
      const activeRequest = (searchQuery.length && requestSearchResp.length)
        ? requestSearchResp[index] : requestList[index];
      await getRequestByID({ usId: activeRequest.usId }, this.headers);
      console.log('set_active hit');
      await this.setState({
        activeRequest,
        isGettingActiveRequest: true,
      });
    }
  };

  getSuggestions = debounce((type, query, extras) => {
    const { getAutocompleteSuggestion } = this.props;
    const params = {
      skip: 0,
      limit: 5,
      query,
      type,
      ...extras,
    };
    getAutocompleteSuggestion(params, this.headers);
  }, 300, true);

  getConversion = (params) => {
    const { getConversion } = this.props;
    getConversion(params, this.headers);
  };

  askConfirmation = (field) => {
    if (field === 'upload') {
      if (this.isUploadTouhed) {
        this.activePopup = 'uploadDialog';
        this.setState({ dismissDialog: true });
        return;
      }
      this.setState({ uploadDialog: false });
    } else if (field === 'requirement') {
      if (this.isAddRequestTouched) {
        this.activePopup = 'requirementDialog';
        this.setState({ dismissDialog: true });
        return;
      }
      this.setState({ requirementDialog: false });
    } else if (field === 'itineraryDetails') {
      this.activePopup = 'itineraryDetailsDialog';
      this.setState({ dismissDialog: true });
    }
  };

  dismissConfirmed = () => {
    this.setState({
      dismissDialog: false,
      [this.activePopup]: false,
    });
    this.activePopup = '';
  };

  handleAddActualSupplier = async (booking, supplier, cp) => {
    const { showSnackbar } = this.props;
    this.setState({
      addActualSupplierDialog: false,
    });
    const url = `${baseURL}booking/addActualSupplier`;
    const response = await postRequest(url, { bookingId: booking._id, actualSupplierId: supplier._id, actualCostPrice: cp }, this.headers);
    if (response.booking) {
      showSnackbar(response.message || 'Supplier added suucessfully', 'success');
      this.getBookings();
    } else {
      showSnackbar(response.message || 'Failed to add supplier', 'error');
    }
  };

  sendPaymentOptions = async (requestId, paymentAmount) => {
    const { showSnackbar } = this.props;
    const url = `${baseURL}request/${requestId}/sendPaymentOptions`;
    try {
      const { paymentLink } = await postRequest(url, { paymentAmount }, this.headers);
      await this.setState({
        paymentLink,
      });
      showSnackbar('Payment details sent to customer successfully', 'success');
    } catch (error) {
      showSnackbar('Action failed', 'error');
    }
  };

  tripActionHandler = async (action, value) => {
    const { showSnackbar } = this.props;
    const sAction = action.toLowerCase();
    const { activeRequest = {} } = this.state;
    const modRequest = { ...activeRequest };
    modRequest.action = action;
    this.setState({
      activeRequest: modRequest,
      activeRequestAction: action,
    });
    if (sAction === 'create_trip') {
      this.toggleState('itineraryDetailsDialog');
    } else if (sAction === 'mark_lost') {
      this.toggleState('markLostDialog');
    } else if (sAction === 'mark_unreachable') {
      this.toggleState('markUnreachableDialog');
    } else if (sAction === 'mark_invalid') {
      this.toggleState('markInvalidDialog');
    } else if (sAction === 'follow_up') {
      this.toggleState('followUpDialog');
    } else if (sAction === 'view_details') {
      this.toggleState('editRequestDrawer');
    } else if (sAction === 'add_whatsapp') {
      this.toggleState('addWhatsAppDialog');
    } else if (sAction === 'upload_docs') {
      this.toggleState('uploadDialog');
    } else if (sAction === 'send_itinerary') {
      this.toggleState('sendItineraryDialog');
    } else if (sAction === 'trip_deferred') {
      this.toggleState('tripDeferredDialog');
    } else if (sAction === 'undefer') {
      this.toggleState('undeferDialog');
    } else if (sAction === 'revive') {
      this.toggleState('reviveDialog');
    } else if (sAction === 'itinerary_template') {
      this.toggleState('itineraryTemplateDialog');
      this.setState({ itineraryTemplateDialogItinerary: value });
    } else if (sAction === 'voucher_dialog') {
      this.toggleState('voucherDialog');
    } else if (sAction === 'send_to_request_bank') {
      this.toggleState('sendToRequestBankDialog');
    } else if (sAction === 'cust_pan_dialog') {
      this.toggleState('custPanDialog');
    } else if (sAction === 'verify_documents') {
      this.toggleState('verifyDocumentDialog');
    } else if (sAction === 'add_requirement') {
      this.toggleState('editRequirementDialog');
    } else if (sAction === 'edit_requirement') {
      this.toggleState('editRequirementDialog');
    } else if (sAction === 'reassign') {
      this.toggleState('requirementDialog');
    } else if (sAction === 'qualify_request') {
      this.toggleState('qualifyRequestDialog');
    } else if (sAction === 'customer_bank_info') {
      this.setState({ paymentAmount: null, paymentLink: null });
      this.toggleState('customerBankInfoDialog');
    } else if (sAction === 'accept_payment') {
      // const { primaryCustomer } = activeRequest;
      // if (activeRequest.b2bPartner || primaryCusstomer?.emailVerifiedAt || primaryCustomer?.phoneVerifiedAt) {
      this.toggleState('acceptPaymentDialog');
      // } else {
      //   let msg = ', please ask customer to verify details';
      //   const emailVerified = Boolean(primaryCustomer?.emailVerifiedAt);
      //   const phoneVerified = Boolean(primaryCustomer?.phoneVerified);
      //   if (!emailVerified) {
      //     msg = `Customer has not verified email yet${msg}`;
      //   } else if (!phoneVerified) {
      //     msg = `Phone is not verified yet${msg}`;
      //   }
      //   showSnackbar(msg, 'error');
      // }
    } else if (sAction === 'cancel_request') {
      for (let i = 0; i < (activeRequest.bookings || []).length; i++) {
        if (activeRequest.bookings[i].status !== 'BOOKING_CANCELLED') {
          showSnackbar('All bookings must be cancelled before cancelling request', 'error');
          return;
        }
      }
      this.toggleState('cancelRequestDialog');
    } else if (sAction === 'refund_from_wallet') {
      if (activeRequest.orderInfo.orderData[0].isRefundLocked) {
        showSnackbar('Previous refund is still in process, please wait un till it\'s completion', 'error');
      } else {
        this.toggleState('refundFromWalletDialog');
      }
    } else if (sAction === 'refund_payment') {
      if (activeRequest.orderInfo.orderData[0].isRefundLocked) {
        showSnackbar('Previous refund is still in process, please wait un till it\'s completion', 'error');
      } else {
        this.toggleState('refundPaymentDialog');
      }
    } else if (sAction === 'new_refund') {
      this.toggleState('newRefundPaymentDialog');
    } else if (sAction === 'resend_confirmation') {
      const { resendConfirmation } = this.props;
      const params = {
        requestId: activeRequest._id,
        communicationType: 'BOOKING_EMAIL',
      };
      resendConfirmation(params, this.headers);
    } else if (sAction === 'send_booking_mail') {
      const url = `${baseURL}request/${activeRequest._id}/sendBookingEmailToOperation`;
      const response = await getRequest(url, {}, this.headers);
      if (response.success) {
        showSnackbar('Booking mail send to operation successfully', 'success');
      } else {
        showSnackbar('Booking mail send to operation failed', 'error');
      }
    } else if (sAction === 'request_quotation') {
      window.open(`https://docs.google.com/forms/d/e/1FAIpQLSchNM0X_W7ndjGUs99b1l0KbcQ7MoeaeIvwPq7f0zTehiL4rQ/viewform?usp=pp_url&entry.1112311976=${activeRequest.usId}`, '_blank');
    }
  };

  bookingActionHandler = (action, index, bookingId) => {
    const { activeRequest } = this.state;
    const booking = activeRequest.bookings.find((bookOb) => bookOb._id === bookingId);
    const { itinerary: itineraryId, supplier } = booking;
    const itinerary = activeRequest.itineraries.find((i) => i._id === itineraryId);
    const sAction = action.toLowerCase();
    if (sAction === 'booking_transaction' || sAction === 'booking_transaction_payment') {
      const totalSuppliers = [...itinerary.suppliers];
      for (let i = 0; i < itinerary.itineraryParts.length; i++) {
        const part = itinerary.itineraryParts[i];
        if ((part.type === 'FLIGHT' || part.type === 'SERVICE')
          && part.supplier?.currency) {
          totalSuppliers.push({
            ...part.supplier,
            supplier: part.supplier,
          });
        }
      }
      const activeSupplier = totalSuppliers.find((s) => s.supplier._id === supplier);
      // if (activeRequest.b2bPartner) {
      //   const { getB2bWalletBalance } = this.props;
      //   getB2bWalletBalance({ partnerId: activeRequest.b2bPartner }, this.headers);
      // }
      this.setState({
        [sAction === 'booking_transaction'
          ? 'transactionDialog'
          : 'transactionPaymentDialog']: true,
        activeBookingId: bookingId,
        activeSupplier,
      });
      return;
    }
    if (sAction === 'cancel_booking') {
      this.setState({
        cancelBookingDialog: true,
        activeBookingId: bookingId,
      });
      return;
    }
    if (sAction === 'add_actual_supplier') {
      this.setState({
        addActualSupplierDialog: true,
        activeBookingId: bookingId,
      });
      return;
    }
    if (itinerary.itineraryVersion === 2) {
      window.open(`/itineraryMaker/request/${activeRequest.usId}/${itineraryId}/${sAction}/${index}`, '__blank');
      return;
    }
    this.setState({
      itineraryDetailsDialog: true,
      itineraryActionType: sAction,
      activeBookingId: bookingId,
      // ...changes,
    });
  };

  handleTransactionRecord = (transactionParams) => {
    const { markBookingTransaction } = this.props;
    const { activeBookingId, activeRequest } = this.state;
    const booking = activeRequest.bookings.find((b) => b._id === activeBookingId);
    markBookingTransaction({
      ...transactionParams,
      bookingId: booking._id,
    }, this.headers, { requestId: activeRequest._id });
  };

  remarkUpdate = (remarkParams) => {
    const { remarkUpdate, requestSearchResp } = this.props;
    const { activeRequest, searchQuery } = this.state;
    const params = {
      ...remarkParams,
      request: activeRequest._id,
      updateIn: (searchQuery.length && requestSearchResp.length) ? 'SEARCH' : 'REQUEST',
    };
    remarkUpdate(params, this.headers);
  };

  sendSelectedItinerary = (itinerary) => {
    const { sendItinerary } = this.props;
    const { activeRequest } = this.state;
    const { _id } = activeRequest;
    const params = {
      _id,
      action: 'SEND_ITINERARY',
      details: {
        itineraryId: itinerary._id,
        link: itinerary.link,
      },
    };
    sendItinerary(params, this.headers);
  };

  markTouched = (field) => {
    if (field === 'isUploadTouched') {
      this.isUploadTouhed = true;
    } else if (field === 'isAddRequestTouched') {
      this.isAddRequestTouched = true;
    }
  };

  uploadHandler = () => {
    // receives files to upload
  };

  updateRequest = (params) => {
    const { updateRequestDetails, saveItineraryDraft } = this.props;
    const { activeRequest, activeRequestAction } = this.state;
    const { _id, status } = activeRequest;
    const eligibleStatus = {
      FRESH: 1,
      FOLLOW_UP: 1,
      UNREACHABLE: 1,
      INVALID: 1,
      BAD_KARMA: 1,
    };
    if ((activeRequestAction === 'ADD_REQUIREMENT' && eligibleStatus[status])) {
      this.requestActionHandler({ details: params });
    } else if (activeRequestAction === 'CONFIRM_REQUIREMENT') {
      saveItineraryDraft({
        details: {
          requirement: params,
          routes: [],
          itineraryParts: [],
          inclusions: [],
          exclusions: [],
          tags: params.tags || [],
          templateItineraryId: params.templateItineraryId,
        },
        requestId: _id,
      }, this.headers);
    } else {
      updateRequestDetails({ _id, ...params }, this.headers);
    }
  };

  handleWhatsappUpdate = (params) => {
    const { updateRequestDetails } = this.props;
    const { activeRequest } = this.state;
    const { _id, primaryCustomerData } = activeRequest;
    updateRequestDetails({
      _id,
      primaryCustomerData: { ...primaryCustomerData, ...params },
    }, this.headers);
  };

  processFiltersToApply = () => {
    const { filters } = this.state;
    if (!filters || !Object.keys(filters).length) {
      return {};
    }
    const {
      starred, interested, tripDates, requestDates, live,
      places, expert, selectedSource, selectedStatus,
    } = filters;
    const source = [];
    const sourceKeys = Object.keys(selectedSource);
    for (let i = 0; i < sourceKeys.length; i++) {
      if (selectedSource[sourceKeys[i]]) {
        source.push(selectedSource[sourceKeys[i]].code);
      }
    }
    const status = [];
    const statusKeys = Object.keys(selectedStatus);
    for (let i = 0; i < statusKeys.length; i++) {
      if (selectedStatus[statusKeys[i]]) {
        status.push(selectedStatus[statusKeys[i]].code);
      }
    }
    return {
      destinations: places && places.items.map((p) => p._id),
      experts: expert && expert.items.map((e) => e._id),
      source,
      status,
      live: Boolean(live),
      starred: Boolean(starred),
      interested: Boolean(interested),
      tripDates: tripDates && tripDates.from ? {
        from: moment(tripDates.from).toISOString(),
        to: moment(tripDates.to).toISOString(),
      } : undefined,
      requestDates: requestDates && requestDates.from ? {
        from: moment(requestDates.from).toISOString(),
        to: moment(requestDates.to).toISOString(),
      } : undefined,
    };
  };

  applyFilterParams = (kind = 'replace') => {
    const { getRequestFilters } = this.props;
    const { filters } = this.state;
    if (Object.keys(filters).length === 0) {
      this.refreshHandler(filters, kind);
      return;
    }
    const filtersToApply = this.processFiltersToApply();
    this.refreshHandler(filtersToApply, kind);
    getRequestFilters({
      selectedFilters: {
        ...filtersToApply,
        status: [],
      },
    }, this.headers);
  };

  filtersHandler = (filters) => {
    this.setState({
      filters,
      skip: 0,
      searchQuery: '',
    }, this.applyFilterParams);
    window.localStorage.setItem('filters', JSON.stringify(filters));
  };

  filterChip = (label, args) => {
    const { classes } = this.props;
    return (
      <Chip
        label={label}
        key={`${label}_${args[0]}`}
        classes={{
          label: classes.chipLabel,
        }}
        className={classes.filterChip}
        deleteIcon={<CloseIcon className={classes.closeIcon} />}
        onDelete={() => this.removeFilterHandler(...args)}
      />
    );
  };

  renderFilters = () => {
    const { filters } = this.state;
    const filterKeys = Object.keys(filters);
    let filterCount = 0;
    const renderedFilters = filterKeys.map((key) => {
      if (key === 'starred' || key === 'live' || key === 'interested') {
        if (filters[key]) {
          filterCount++;
          if (key === 'starred') {
            return this.filterChip('Starred', ['starred']);
          }

          if (key === 'interested') {
            return this.filterChip('Interested', ['interested']);
          }

          return this.filterChip('Active Requests', ['live']);
        }
        return null;
      }
      if (key === 'selectedSource' || key === 'selectedStatus') {
        return Object.values(filters[key]).map((filter, index) => {
          if (!filter) {
            return null;
          }
          filterCount++;
          return this.filterChip(filter.title, [key, index]);
        });
      }
      if (key === 'expert' || key === 'places') {
        return filters[key].items.map((filter, index) => {
          filterCount++;
          return this.filterChip(filter.name || filter.fullName, [key, index]);
        });
      }
      if ((key === 'tripDates' || key === 'requestDates') && filters[key] && filters[key].from) {
        filterCount++;
        const label = `${moment(filters[key].from).format('DD MMM YY')} - ${moment(filters[key].to)
          .format('DD MMM YY')}`;
        return this.filterChip(label, [key]);
      }
      return null;
    });
    return {
      filterCount,
      renderedFilters,
    };
  };

  removeFilterHandler = (key, index) => {
    const { filters } = this.state;
    const modFilters = { ...filters };
    if (key === 'tripDates' || key === 'requestDates') {
      delete modFilters[key].from;
    }
    if (key === 'starred' || key === 'live' || key === 'interested') {
      delete modFilters[key];
    }
    if (key === 'expert' || key === 'places') {
      const modItem = { ...modFilters[key] };
      modItem.items.splice(index, 1);
      modFilters[key] = modItem;
    }
    if (key === 'selectedSource' || key === 'selectedStatus') {
      const modObj = {};
      const itemKeys = Object.keys(filters[key]);
      for (let i = 0; i < itemKeys.length; i++) {
        if (i !== index) {
          modObj[itemKeys[i]] = filters[key][itemKeys[i]];
        }
      }
      modFilters[key] = modObj;
    }
    this.setState({
      filters: modFilters,
      skip: 0,
    }, this.applyFilterParams);
    window.localStorage.setItem('filters', JSON.stringify(modFilters));
  };

  resetFilter = () => {
    this.setState({
      filters: {},
      skip: 0,
    }, this.applyFilterParams);
    window.localStorage.removeItem('filters');
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
    this.getSuggestions('experts', value);
  };

  handleSelection = (accessor, field, item) => {
    this.setState({
      [field]: item[accessor],
      ownerItem: item,
    });
  };

  assignRequestsHandler = () => {
    const { reAssignRequest, showSnackbar } = this.props;
    const { ownerItem, requestSelections } = this.state;
    if (ownerItem.fullName) {
      const clonedSelections = Object.keys(deepClone(requestSelections));
      this.setState({ requestSelections: {} }, () => {
        const expert = ownerItem._id;
        const params = {
          expert,
          requests: clonedSelections,
        };
        reAssignRequest(params, this.headers);
      });
    } else {
      showSnackbar('Select expert to assign to', 'error');
    }
  };

  requestActionHandler = (details) => {
    const { updateStatus } = this.props;
    const { activeRequest, activeRequestAction } = this.state;
    const { _id } = activeRequest;
    this.setState({ [this.activeDialog]: false });
    const skip = activeRequestAction.toLowerCase() === 'follow_up' && this.mode === 'focused';
    updateStatus({
      ...details,
      _id,
      skip,
      action: activeRequestAction.toLowerCase() === 'follow_up' ? details.details.action : activeRequestAction,
    }, this.headers);
  };

  handleUndeferRequest = () => {
    const { updateStatus } = this.props;
    const { activeRequest } = this.state;
    const { _id } = activeRequest;
    updateStatus({
      _id,
      action: 'UNDEFER',
    }, this.headers);
    this.setState({
      undeferDialog: false,
    });
  }

  cancelItineraryHandler = (details) => {
    const { cancelItinerary } = this.props;
    const { activeRequest, searchQuery } = this.state;
    const { _id } = activeRequest;
    this.setState({ [this.activeDialog]: false });
    cancelItinerary({
      ...details,
      requestId: _id,
      searchKey: searchQuery.length ? 'SEARCH' : 'REQUEST',
    }, this.headers);
  };

  acceptPaymentHandler = (paymentData) => {
    const { acceptPayment, requestSearchResp } = this.props;
    const { searchQuery, activeRequest } = this.state;
    const params = {
      paymentData,
      refData: {
        requestId: activeRequest._id,
        type: searchQuery.length && requestSearchResp.length ? 'SEARCH' : 'REQUEST',
      },
    };
    acceptPayment(params, this.headers);
  };

  refundPaymentHandler = (paymentData) => {
    const { refundPayment, requestSearchResp } = this.props;
    const { searchQuery, activeRequest } = this.state;
    const params = {
      paymentData,
      refData: {
        requestId: activeRequest._id,
        type: searchQuery.length && requestSearchResp.length ? 'SEARCH' : 'REQUEST',
      },
    };
    refundPayment(params, this.headers);
  };

  handlePaymentRecord = (paymentData) => {
    const { refundToWallet, requestSearchResp } = this.props;
    const { searchQuery, activeRequest } = this.state;
    const params = {
      paymentData,
      refData: {
        requestId: activeRequest._id,
        type: searchQuery.length && requestSearchResp.length ? 'SEARCH' : 'REQUEST',
      },
    };
    refundToWallet(params, this.headers);
  };

  refundFromWallet = (paymentData) => {
    const { refundFromWallet, requestSearchResp } = this.props;
    const { searchQuery, activeRequest } = this.state;
    const params = {
      paymentData,
      fromWallet: true,
      refData: {
        requestId: activeRequest._id,
        type: searchQuery.length && requestSearchResp.length ? 'SEARCH' : 'REQUEST',
      },
    };
    refundFromWallet(params, this.headers);
  };

  sendItinerary = (file, link, fileName) => {
    const { sendItinerary } = this.props;
    const { activeRequest, activeRequestAction } = this.state;
    const { _id } = activeRequest;
    let signedParams = {};
    if (file && file.name) {
      signedParams = {
        files: [{
          fileName: file.name,
          fileCategory: 'ITINERARY_PART',
          ttlMinutes: 2,
          extension: 'pdf',
        }],
      };
    }
    const params = {
      signedParams,
      link,
      fileName,
      file,
      itineraryId: this.activeItinerary ? this.activeItinerary._id : undefined,
      updateParams: {
        _id,
        action: activeRequestAction,
      },
    };
    this.setState({ [this.activeDialog]: false });
    sendItinerary(params, this.headers);
  };

  resetAssignments = () => {
    this.setState({ requestSelections: {} });
  };

  itineraryActionRequestHandler = (action, itinerary) => {
    const {
      deleteItinerary, updateStatus,
      requestSearchResp, saveItineraryDraft,
      saveAsTemplateItinerary, showSnackbar,
    } = this.props;
    const { searchQuery, activeRequest } = this.state;
    let changes = {};
    this.activeItinerary = itinerary;
    if (action === 'quote') {
      // this.activeItinerary.actionType = action;
      changes = { itineraryActionType: action };
    } else if (action === 'edit') {
      if (itinerary.itineraryVersion === 2) {
        window.open(`/itineraryMaker/request/${activeRequest.usId}/${itinerary._id}`, '_blank');
        return;
      }
      changes = { itineraryActionType: action };
    } else if (action === 'preview_itinerary') {
      window.open(`/itineraryMaker/request/${activeRequest.usId}/${itinerary._id}/view`, '_blank');
      return;
    } else if (action === 'clone_edit_itinerary') {
      const itineraryParts = itinerary.itineraryParts.map((p) => ({
        ...p,
        _id: undefined,
        version: undefined,
      }));
      saveItineraryDraft({
        details: {
          ...itinerary,
          requirement: convertRequirement(processRequirement(itinerary.requirement)),
          itineraryParts,
          routes: itinerary.routes.map((r) => ({
            ...r,
            place: r.place._id,
          })),
          suppliers: [],
          identifier: undefined,
          status: undefined,
          pricing: undefined,
          _id: undefined,
          document: undefined,
          created_at: undefined,
          modified_at: undefined,
        },
        requestId: activeRequest._id,
      }, this.headers);
      this.setState({ activeRequestAction: action });
      return;
    } else if (action === 'create') {
      changes = { itineraryActionType: action };
    } else if (action === 'create_ops_itinerary') {
      changes = { itineraryActionType: action };
    } else if (action === 'modify_itinerary') {
      if (itinerary.itineraryVersion === 2) {
        window.open(`/itineraryMaker/request/${activeRequest.usId}/${itinerary._id}/modify`, '_blank');
        return;
      }
      changes = { itineraryActionType: action };
    } else if (action === 'modify_itinerary_tech') {
      if (itinerary.itineraryVersion === 2) {
        window.open(`/itineraryMaker/request/${activeRequest.usId}/${itinerary._id}/modify_tech`, '_blank');
        return;
      }
      changes = { itineraryActionType: action };
    } else if (action === 'approve_reject_itinerary') {
      if (itinerary.itineraryVersion === 2) {
        window.open(`/itineraryMaker/request/${activeRequest.usId}/${itinerary._id}/approve`, '_blank');
        return;
      }
      changes = { itineraryActionType: action };
    } else if (action === 'generate_voucher') {
      // this.setState({
      //   voucherDialog: true,
      //   itineraryActionType: action,
      // });
      // return;
      changes = { itineraryActionType: action };
    } else if (action === 'cust_pan_upload') {
      changes = { itineraryActionType: action };
    } else if (action === 'delete') {
      const { _id } = activeRequest;
      deleteItinerary({
        // id: itinerary._id,
        itineraryId: itinerary._id,
        requestId: _id,
        requestType: searchQuery.length && requestSearchResp.length ? 'SEARCH' : 'REQUEST',
      }, this.headers);
      return;
    } else if (action === 'save_as_template') {
      saveAsTemplateItinerary({
        itineraryId: itinerary._id,
        isTemplate: true,
        title: itinerary.title,
      }, this.headers);
      this.toggleState('itineraryTemplateDialog');
      showSnackbar('Template saved', 'success');
      return;
    } else if (action === 'remove_as_template') {
      saveAsTemplateItinerary({
        itineraryId: itinerary._id,
        isTemplate: false,
      }, this.headers);
      showSnackbar('Template removed', 'success');
      return;
    } else if (action === 'archive') {
      updateStatus({
        _id: activeRequest._id,
        action: 'TOGGLE_ARCHIVE_ITINERARY',
        details: {
          itineraryId: itinerary._id,
          archive: true,
        },
      });
      showSnackbar('Itinerary Archived', 'success');
      return;
    } else if (action === 'unarchive') {
      updateStatus({
        _id: activeRequest._id,
        action: 'TOGGLE_ARCHIVE_ITINERARY',
        details: {
          itineraryId: itinerary._id,
          archive: false,
        },
      });
      showSnackbar('Itinerary unarchived', 'success');
      return;
    } else if (action === 'request_booking') {
      if (itinerary.itineraryVersion === 2) {
        window.open(`/itineraryMaker/request/${activeRequest.usId}/${itinerary._id}/booking`, '_blank');
        return;
      }
      // this.activeItinerary.actionType = action;
      changes = { itineraryActionType: action };
    } else if (action === 'mark_accepted') {
      updateStatus({
        details: {
          ...itinerary,
          itineraryId: this.activeItinerary && this.activeItinerary._id,
        },
        _id: activeRequest._id,
        action: 'ACCEPT_ITINERARY',
      }, this.headers);
      return;
    } else if (action === 'send') {
      if (itinerary.link) {
        this.activeDialog = 'sendItineraryDialog';
        const itnAction = itinerary.sendCount > 0 ? 'RESEND_ITINERARY' : 'SEND_ITINERARY';
        this.setState({ activeRequestAction: itnAction }, () => {
          this.sendItinerary(null, itinerary.link, itinerary.fileName);
        });
      } else {
        this.setState({
          sendItineraryDialog: true,
          activeRequestAction: 'SEND_ITINERARY',
        });
      }
      return;
    } else if (action === 'send_new') {
      this.setState({
        confirmRequirementDialog: true,
        activeRequestAction: 'CONFIRM_REQUIREMENT',
      });
      return;
    } else if (action === 'view_itinerary') {
      // this.activeItinerary.actionType = action;
      changes = { itineraryActionType: action };
      if (itinerary.itineraryVersion === 2) {
        window.open(`/itineraryMaker/request/${activeRequest.usId}/${itinerary._id}/view`, '_blank');
        return;
      }
    } else if (action === 'cancel_itinerary') {
      this.setState({ cancelItineraryDialog: true });
      return;
    }
    this.setState({ itineraryDetailsDialog: true, ...changes });
  };

  itineraryActionHandler = (itinerary) => {
    const { updateStatus, expert, generateVoucher } = this.props;
    const { activeRequest, itineraryActionType, searchQuery } = this.state;
    const { _id } = activeRequest;
    let action = '';
    if (itineraryActionType === 'quote') {
      action = 'REQUEST_QUOTATION';
    } else if (itineraryActionType === 'request_booking') {
      action = 'REQUEST_BOOKING';
    } else if (itineraryActionType === 'create') {
      action = 'SEND_ITINERARY';
    } else if (itineraryActionType === 'create_ops_itinerary' && expert.roles.includes(ROLES.OPERATION)) {
      action = 'REQUEST_BOOKING';
    } else if (itineraryActionType === 'edit') {
      action = 'UPDATE_ITINERARY';
    } else if (itineraryActionType === 'modify_itinerary_tech') {
      action = 'MODIFY_BOOKING_TECH';
    } else if (itineraryActionType === 'modify_itinerary') {
      action = 'MODIFY_BOOKING';
    } else if (itineraryActionType === 'generate_voucher') {
      generateVoucher({
        ...itinerary,
        _id,
        updateKey: searchQuery.length ? 'SEARCH' : 'REQUEST',
      }, this.headers);
      return;
    } else if (itineraryActionType === 'cust_pan_upload') {
      generateVoucher({
        ...itinerary,
        _id,
        updateKey: searchQuery.length ? 'SEARCH' : 'REQUEST',
      }, this.headers);
      return;
    }
    updateStatus({
      details: {
        ...itinerary,
        itineraryId: this.activeItinerary && this.activeItinerary._id,
      },
      _id,
      action,
    }, this.headers);
  };

  approveRejectItineraryChanges = (action, reason) => {
    const { updateStatus } = this.props;
    const { activeRequest: { _id } } = this.state;
    const baseAction = '_BOOKING_MODIFICATION';
    const finalAction = `${action === 'reject' ? 'REJECT' : 'APPROVE'}${baseAction}`;
    let sendEmailToOps = false;
    if (action === 'approve_with_mail') {
      sendEmailToOps = true;
    }
    updateStatus({
      details: {
        reason,
        sendEmailToOps,
        itineraryId: this.activeItinerary && this.activeItinerary._id,
      },
      _id,
      action: finalAction,
    }, this.headers);
  };

  onInstallmentsUpdate = (params) => {
    const { modifyInstallments } = this.props;
    const { activeRequest } = this.state;
    const { _id } = activeRequest;
    this.setState({ orderModificationDialog: false });
    modifyInstallments({
      ...params,
      _id,
    }, this.headers);
  };

  orderUpdateRequestHandler = () => {
    this.setState({ orderModificationDialog: true });
  };

  onBookingAction = (bookingParams) => {
    const { bookingAction } = this.props;
    const { itineraryActionType, activeRequest } = this.state;
    bookingAction({
      bookingParams: {
        ...bookingParams,
        requestId: activeRequest._id,
      },
      type: itineraryActionType,
      requestId: activeRequest._id,
    }, this.headers);
  };

  handleBookingCancel = (params) => {
    const { cancelBooking } = this.props;
    cancelBooking(params, this.headers);
  };

  handleTravellerUpdate = (_action, travellerDetails) => {
    this.setState((prevState) => {
      const newActiveReq = deepClone(prevState.activeRequest);
      newActiveReq.voucherDetails = newActiveReq.voucherDetails || {};
      newActiveReq.voucherDetails.travellerDetails = travellerDetails;
      return {
        activeRequest: newActiveReq,
      };
    });
  };

  handleOtherDocUpdate = (docs) => {
    this.setState((prevState) => {
      const newActiveReq = deepClone(prevState.activeRequest);
      newActiveReq.voucherDetails = newActiveReq.voucherDetails || {};
      newActiveReq.voucherDetails.otherDocuments = docs;
      return {
        activeRequest: newActiveReq,
      };
    });
  };

  handleRequestBankSubmit = () => {
    const { updateStatus } = this.props;
    const { activeRequest } = this.state;
    const { _id } = activeRequest;
    updateStatus({
      _id,
      action: 'SEND_TO_REQUEST_BANK',
    }, this.headers);
    this.setState({
      sendToRequestBankDialog: false,
    });
  }

  handleTravellerSubmit = () => {
    const itineraryActionType = 'generate_voucher';
    const { generateVoucher } = this.props;
    const {
      activeRequest: request,
      searchQuery,
    } = this.state;
    if (itineraryActionType === 'generate_voucher') {
      const voucherParams = {
        voucherDetails: {
          supportNumbers: {
            ground: request.voucherDetails?.supportNumbers?.ground || '',
            flynote: '+918061930480',
            expert: request.expert.mobile,
          },
          notes: request.voucherDetails?.notes || [],
          travellerDetails: request.voucherDetails?.travellerDetails || {},
          otherDocuments: request.voucherDetails?.otherDocuments || [],
          customerItineraryParts: [],
        },
      };
      generateVoucher({
        ...voucherParams,
        _id: request._id,
        updateKey: searchQuery.length ? 'SEARCH' : 'REQUEST',
      }, this.headers);
      this.setState({
        loaderDialog: true,
      });
    }
  };

  getEmptyData = (renderRequests, requests) => {
    const { requestList, requestSearchResp } = this.props;
    const { activeTab, searchQuery } = this.state;
    let showEmpty = false;
    let emptyImg = noResultImg;
    let emptyTitle = 'No results found';
    let emptyDescription = 'Search tip : Check user name spellings, user id, number or email and try again';
    let listToConsider;
    if (searchQuery.length && requestSearchResp.length) {
      listToConsider = requestSearchResp;
    } else {
      listToConsider = requestList;
    }
    if (requests === 0 && activeTab === 'attention' && listToConsider.length) {
      showEmpty = true;
      emptyImg = emptyAttentionImg;
      emptyDescription = 'We are glad to have you with all of these qualities. We could not have thought of any better moment than today to appreciate you and wish you good luck for all your future endeavours.';
      emptyTitle = 'Hard work, Loyalty and Diligence make the best employees.';
    }
    // else if (attention === 0 && activeTab === 'attention' && renderRequests.length === 0) {
    //   showEmpty = true;
    //   emptyImg = emptyNewAttentionImg;
    //   emptyDescription = 'All customers who needs
    // your immediate attention will be displayed here.';
    //   emptyTitle = 'Needs attention';
    // }
    // else if (live === 0 && activeTab === 'live') {
    //   showEmpty = true;
    //   emptyImg = emptyLiveImg;
    //   emptyTitle = 'Live';
    //   emptyDescription = 'All the customer who are
    // being processed further are being displayed here';
    // }
    if (searchQuery && renderRequests.length === 0) {
      showEmpty = true;
      emptyImg = noResultImg;
      emptyTitle = 'No results found';
      emptyDescription = 'Search tip : Check user name spellings, user id, number or email and try again';
    }
    if (this.mode === 'focused') {
      showEmpty = false;
    }
    return {
      showEmpty,
      emptyImg,
      emptyTitle,
      emptyDescription,
    };
  };

  setZIndex = (index) => {
    document.getElementsByTagName('body')[0].lastElementChild.style.zIndex = index;
  };

  handleClose = (event) => {
    if (this.notificationsRef.current && this.notificationsRef.current.contains(event.target)) {
      return;
    }
    this.setState({ showNotificationsPopper: false });
    this.setZIndex(0);
  };

  copyPaymentLink = async () => {
    const { showSnackbar } = this.props;
    const { paymentLink } = this.state;
    await navigator.clipboard.writeText(paymentLink);
    showSnackbar('Payment link copied', 'success');
  };

  render() {
    const {
      classes,
      requestList,
      isFetchingRequests,
      suggestions,
      meta,
      isAddingRequest,
      token,
      expert,
      requestSearchResp,
      addRequestError,
      conversion,
      requestFiltersResp,
      isUpdatingStatus,
      isSearchingRequest,
      isAcceptingPayment,
      isResendingConfirmation,
      isRefundingPayment,
      isCancellingItinerary,
      isRefundingFromWallet,
      isRefundingToWallet,
      isFetchingSuggestions,
      isSavingItineraryDraft,
      isMarkingBookingTransaction,
      isCancellingBooking,
      isGettingBookings,
      bookings,
      showSnackbar,
      getB2bWalletBalanceResp,
      isGettingB2bWalletBalance,
      refundData,
      virtualAccountData,
      balances,
    } = this.props;
    const {
      activeRequest,
      filterDrawer,
      activeTab,
      voucherDialog,
      custPanDialog,
      verifyDocumentDialog,
      sendToRequestBankDialog,
      requirementDialog,
      markUnreachableDialog,
      markInvalidDialog,
      searchQuery,
      editRequestDrawer,
      dismissDialog,
      markLostDialog,
      followUpDialog,
      uploadDialog,
      sendItineraryDialog,
      addWhatsAppDialog,
      requestSelections,
      filters,
      ownerVal,
      activeSupplier,
      itineraryDetailsDialog,
      itineraryActionType,
      tripDeferredDialog,
      undeferDialog,
      editRequirementDialog,
      qualifyRequestDialog,
      activeBookingId,
      orderModificationDialog,
      acceptPaymentDialog,
      refundPaymentDialog,
      activeRequestAction,
      cancelRequestDialog,
      confirmRequirementDialog,
      cancelItineraryDialog,
      refundFromWalletDialog,
      newRefundPaymentDialog,
      transactionDialog,
      transactionPaymentDialog,
      reviveDialog,
      itineraryTemplateDialog,
      itineraryTemplateDialogItinerary,
      cancelBookingDialog,
      customerBankInfoDialog,
      loaderDialog,
      addActualSupplierDialog,
      isGettingActiveRequest,
      paymentAmount,
      showNotificationsPopper,
      paymentLink,
    } = this.state;
    const { roles } = expert;
    const requestsToRender = _.isEmpty(searchQuery) ? requestList : requestSearchResp;
    // const { attention, live } = this.getRequestCount(renderRequests);
    const {
      showEmpty, emptyDescription, emptyImg, emptyTitle,
    } = this.getEmptyData(requestsToRender, requestsToRender.length);
    const { filterCount, renderedFilters } = this.renderFilters();
    // let displayCount = false;
    // if (searchQuery.length || renderRequests.length) {
    //   displayCount = true;
    // }
    const filteredExperts = suggestions.experts.filter((e) => e.delete === false);
    let activeBooking = {};
    if (cancelBookingDialog && activeRequest.bookings) {
      activeBooking = activeRequest.bookings.find((b) => b._id === activeBookingId);
    }
    return (
      <div className={classes.container}>
        <Drawer
          anchor="right"
          open={filterDrawer}
          onClose={() => this.toggleState('filterDrawer')}
        >
          <Filters
            requestFilters={requestFiltersResp}
            suggestions={suggestions}
            getSuggestions={this.getSuggestions}
            filters={filters}
            roles={expert.roles}
            onUpdate={(newFilters) => this.filtersHandler(newFilters)}
            onDismiss={() => this.toggleState('filterDrawer')}
          />
        </Drawer>
        <Drawer
          anchor="right"
          open={editRequestDrawer}
          onClose={() => this.toggleState('editRequestDrawer')}
        >
          <EditRequest
            expert={expert}
            request={activeRequest}
            suggestions={suggestions}
            getSuggestions={this.getSuggestions}
            onUpdate={this.updateRequest}
            onDismiss={() => this.toggleState('editRequestDrawer')}
          />
        </Drawer>
        <Dialog
          open={itineraryDetailsDialog}
          maxWidth="lg"
          scroll="paper"
          onClose={() => {
            if (ITINERARY_READ_MODES[itineraryActionType]) {
              this.setState({ itineraryDetailsDialog: false });
            } else {
              this.askConfirmation('itineraryDetails');
            }
          }}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="itinerary details"
        >
          <ItineraryDetails
            meta={meta}
            expert={expert}
            request={activeRequest}
            itinerary={this.activeItinerary}
            activeBookingId={activeBookingId}
            isUpdatingStatus={isUpdatingStatus}
            onClose={() => this.toggleState('itineraryDetailsDialog')}
            onDismiss={() => this.askConfirmation('itineraryDetails')}
            onBookingModify={this.onBookingAction}
            onCreate={this.itineraryActionHandler}
            itineraryActionType={itineraryActionType}
            suggestions={suggestions}
            isFetchingSuggestions={isFetchingSuggestions}
            getSuggestions={this.getSuggestions}
            conversion={conversion}
            getConversion={this.getConversion}
            approveRejectChanges={this.approveRejectItineraryChanges}
          />
        </Dialog>
        <Dialog
          open={cancelBookingDialog}
          maxWidth="lg"
          scroll="paper"
          onClose={() => this.toggleState('cancelBookingDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="cancel booking"
        >
          <CancelBookingRequest
            loading={isCancellingBooking}
            booking={activeBooking}
            onCancel={this.handleBookingCancel}
            onDismiss={() => this.toggleState('cancelBookingDialog')}
          />
        </Dialog>
        <Dialog
          open={dismissDialog}
          onClose={() => this.toggleState('dismissDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="requirement"
        >
          <Confirmation
            heading="Unsaved changes"
            description="Are you sure you want to discard the changes?"
            onReject={() => this.toggleState('dismissDialog')}
            onDismiss={() => this.toggleState('dismissDialog')}
            onConfirm={this.dismissConfirmed}
          />
        </Dialog>
        <Dialog
          open={addActualSupplierDialog}
          onClose={() => this.toggleState('addActualSupplierDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="addActualSupplierDialog"
        >
          <AddActualSupplier
            onDismiss={() => this.toggleState('addActualSupplierDialog')}
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            booking={activeRequest?.bookings?.find((b) => b._id === activeBookingId)}
            bookingOb={(bookings[activeRequest._id] || []).find((booking) => booking._id === activeBookingId)}
            addActualSupplier={this.handleAddActualSupplier}
          />
        </Dialog>
        <Dialog
          open={voucherDialog}
          onClose={() => this.toggleState('voucherDialog')}
          classes={{
            paper: classes.paper,
          }}
          maxWidth="lg"
          aria-labelledby="voucher dialog"
        >
          <Header
            title="Upload customer documents"
            onDismiss={() => this.toggleState('voucherDialog')}
            img={uploadImg}
          />
          <Travellers
            itineraryActionType={itineraryActionType}
            isProcessing={isUpdatingStatus}
            requirement={activeRequest.requirement}
            request={activeRequest}
            travellerDetails={activeRequest.voucherDetails?.travellerDetails || {}}
            otherDocuments={activeRequest.voucherDetails?.otherDocuments || []}
            onUpdate={this.handleTravellerUpdate}
            onDocUpdate={this.handleOtherDocUpdate}
            nextHandler={this.handleTravellerSubmit}
          />
        </Dialog>
        <Dialog
          open={custPanDialog}
          onClose={() => this.toggleState('custPanDialog')}
          classes={{
            paper: classes.paper,
          }}
          maxWidth="lg"
          aria-labelledby="cust pan dialog"
        >
          <Header
            title="Upload customer pan"
            onDismiss={() => this.toggleState('custPanDialog')}
            img={uploadImg}
          />
          <PanDetails
            itineraryActionType={itineraryActionType}
            isProcessing={isUpdatingStatus}
            requirement={activeRequest.requirement}
            travellerDetails={activeRequest.voucherDetails?.travellerDetails || {}}
            onUpdate={this.handleTravellerUpdate}
            nextHandler={this.handleTravellerSubmit}
          />
        </Dialog>
        <Dialog
          open={verifyDocumentDialog}
          onClose={() => this.toggleState('verifyDocumentDialog')}
          classes={{
            paper: classes.paper,
          }}
          maxWidth="lg"
          aria-labelledby="verify customers dialog"
        >
          <Header
            title="Verify customer document"
            onDismiss={() => this.toggleState('verifyDocumentDialog')}
            img={uploadImg}
          />
          <CustomerVerification
            expert={expert}
            request={activeRequest}
            headers={this.headers}
            showSnackbar={showSnackbar}
            onClose={() => this.toggleState('verifyDocumentDialog')}
          />
        </Dialog>
        <Dialog
          open={sendToRequestBankDialog}
          onClose={() => this.toggleState('sendToRequestBankDialog')}
          classes={{
            paper: classes.paper,
          }}
          maxWidth="lg"
          aria-labelledby="Send to lead bank"
        >
          <Confirmation
            heading="Confirm sending to lead bank ?"
            description="Are you sure you want to send back to lead bank ?"
            onReject={() => this.toggleState('sendToRequestBankDialog')}
            onDismiss={() => this.toggleState('sendToRequestBankDialog')}
            onConfirm={this.handleRequestBankSubmit}
          />
        </Dialog>
        <Dialog
          open={reviveDialog}
          onClose={() => this.toggleState('reviveDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="revive dialog"
        >
          <Revival
            onDismiss={() => this.toggleState('reviveDialog')}
            onRevive={this.requestActionHandler}
          />
        </Dialog>
        <Dialog
          open={itineraryTemplateDialog}
          onClose={() => this.toggleState('itineraryTemplateDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="itineraryTemplate dialog"
        >
          <ItineraryTemplate
            itinerary={itineraryTemplateDialogItinerary}
            onDismiss={() => this.toggleState('itineraryTemplateDialog')}
            onSave={(itinerary) => this.itineraryActionRequestHandler('save_as_template', itinerary)}
          />
        </Dialog>
        <Dialog
          open={transactionDialog}
          onClose={() => this.toggleState('transactionDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="booking transaction"
        >
          <TransactionBooking
            walletBalance={getB2bWalletBalanceResp}
            isLoading={isGettingB2bWalletBalance}
            supplier={activeSupplier}
            booking={activeRequest?.bookings?.find((b) => b._id === activeBookingId)}
            bookingOb={(bookings[activeRequest._id] || []).find((booking) => booking._id === activeBookingId)}
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            loading={isMarkingBookingTransaction}
            onDismiss={() => this.toggleState('transactionDialog')}
            onSubmit={this.handleTransactionRecord}
          />
        </Dialog>
        <Dialog
          open={transactionPaymentDialog}
          onClose={() => this.toggleState('transactionPaymentDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="booking transaction"
        >
          <TransactionBooking
            createPayment
            walletBalance={getB2bWalletBalanceResp}
            isLoading={isGettingB2bWalletBalance}
            supplier={activeSupplier}
            booking={activeRequest?.bookings?.find((b) => b._id === activeBookingId)}
            bookingOb={(bookings[activeRequest._id] || []).find((booking) => booking._id === activeBookingId)}
            balance={balances[activeRequest._id] || ''}
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            loading={isMarkingBookingTransaction}
            onDismiss={() => this.toggleState('transactionPaymentDialog')}
            onSubmit={this.handleTransactionRecord}
          />
        </Dialog>
        <Dialog
          open={editRequirementDialog}
          onClose={() => this.toggleState('editRequirementDialog')}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="edit requirement"
        >
          <UpdateTripDetails
            meta={meta}
            suggestions={suggestions}
            requirement={(activeRequest || {}).requirement || {}}
            tags={(activeRequest || {}).tags || {}}
            getSuggestions={this.getSuggestions}
            onDismiss={() => this.toggleState('editRequirementDialog')}
            onUpdate={this.updateRequest}
          />
        </Dialog>
        <Dialog
          open={customerBankInfoDialog}
          onClose={() => this.toggleState('customerBankInfoDialog')}
          maxWidth="lg"
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="customer bank info"
        >
          <Header
            title="Customer bank info"
            onDismiss={() => this.toggleState('customerBankInfoDialog')}
            variant="back"
            img={arrowBack}
          />
          {
            (expert.roles.includes(ROLES.PAYMENT) || expert.roles.includes(ROLES.ADMIN))
              ? (
                <CustomerBankInfo
                  request={activeRequest || {}}
                  virtualAccountData={virtualAccountData || {}}
                />
              ) : null
          }

          <div className={classes.paymentLinkContainer}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <LabelInput
                extraClass={classes.left}
                placeholder="enter amount"
                label="Payment Amount (optional)"
                value={paymentAmount}
                onChange={(value) => this.updatePaymentAmount(value)}
              />
              {
                paymentLink
                  ? (
                    <Button
                      variant="plain"
                      aria-describedby={paymentLink}
                      className={classes.copyPaymentLinkButton}
                      onClick={this.copyPaymentLink}
                    >
                      <CloneIcon className={classes.copyIcon} />
                      Copy Link
                    </Button>
                  )
                  : null
              }
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 25 }}>
              <Button
                className={classes.sendPaymentDetailsButton}
                classes={{
                  label: classes.buttonLabel,
                }}
                onClick={() => this.sendPaymentOptions(activeRequest._id, paymentAmount * 100)}
              >
                <Send className={classes.sendIcon} />
                Send Payment Details
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={qualifyRequestDialog}
          onClose={() => this.toggleState('qualifyRequestDialog')}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="edit requirement"
        >
          <AddRequirement
            meta={meta}
            expert={expert}
            showHotness
            request={activeRequest}
            activeRequestAction={activeRequestAction}
            markTouched={() => {
            }}
            suggestions={suggestions}
            isAddingRequest={isAddingRequest}
            getSuggestions={this.getSuggestions}
            updateRequest={this.requestActionHandler}
            onDismiss={() => this.toggleState('qualifyRequestDialog')}
            reAssignRequest={this.requestActionHandler}
            addRequestError={addRequestError}
          />
        </Dialog>
        <Dialog
          open={markLostDialog}
          onClose={() => this.toggleState('markLostDialog')}
          aria-labelledby="requirement"
        >
          <LostReason
            suggestions={suggestions}
            getSuggestions={this.getSuggestions}
            lostReasons={meta.lostReasons}
            onDismiss={() => this.toggleState('markLostDialog')}
            onSubmit={this.requestActionHandler}
          />
        </Dialog>
        <Dialog
          open={tripDeferredDialog}
          onClose={() => this.toggleState('tripDeferredDialog')}
          aria-labelledby="requirement"
        >
          <TripDeferred
            tripDeferredReasons={meta.tripDeferredReasons || []}
            onDismiss={() => this.toggleState('tripDeferredDialog')}
            onSubmit={this.requestActionHandler}
          />
        </Dialog>
        <Dialog
          open={undeferDialog}
          onClose={() => this.toggleState('undeferDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="requirement"
        >
          <Confirmation
            heading="Undefer Request"
            description="Are you sure you want to undefer the request?"
            onReject={() => this.toggleState('undeferDialog')}
            onDismiss={() => this.toggleState('undeferDialog')}
            onConfirm={this.handleUndeferRequest}
          />
        </Dialog>
        <Dialog
          open={uploadDialog}
          onClose={() => this.askConfirmation('upload')}
          maxWidth="md"
          aria-labelledby="upload documents"
        >
          <Upload
            markTouched={() => this.markTouched('isUploadTouched')}
            onDismiss={() => this.askConfirmation('upload')}
            onUpload={(files) => this.uploadHandler(files)}
          />
        </Dialog>
        <Dialog
          open={followUpDialog}
          onClose={() => this.toggleState('followUpDialog')}
        >
          <FollowUpRequest
            onSubmit={this.requestActionHandler}
            onDismiss={() => this.toggleState('followUpDialog')}
          />
        </Dialog>
        <Dialog
          open={addWhatsAppDialog}
          classes={{
            paper: classes.visiblePaper,
          }}
          onClose={() => this.toggleState('addWhatsAppDialog')}
        >
          <AddWhatsApp
            request={activeRequest}
            suggestions={suggestions}
            getSuggestions={this.getSuggestions}
            onSubmit={this.handleWhatsappUpdate}
            onDismiss={() => this.toggleState('addWhatsAppDialog')}
          />
        </Dialog>
        <Dialog
          open={requirementDialog}
          maxWidth="lg"
          onClose={() => this.askConfirmation('requirement')}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="requirement"
        >
          <AddRequirement
            meta={meta}
            expert={expert}
            request={activeRequest}
            showHotness={expert.roles.includes(ROLES.FILTRATION)}
            activeRequestAction={activeRequestAction}
            markTouched={() => this.markTouched('isAddRequestTouched')}
            suggestions={suggestions}
            isAddingRequest={isAddingRequest}
            getSuggestions={this.getSuggestions}
            addRequest={this.addRequest}
            onDismiss={() => this.askConfirmation('requirement')}
            reAssignRequest={this.requestActionHandler}
            addRequestError={addRequestError}
          />
        </Dialog>
        <Dialog
          open={markUnreachableDialog}
          onClose={() => this.toggleState('markUnreachableDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="unreachable"
        >
          <Unreachable
            request={activeRequest}
            onSubmit={this.requestActionHandler}
            onDismiss={() => this.toggleState('markUnreachableDialog')}
          />
        </Dialog>
        <Dialog
          open={markInvalidDialog}
          onClose={() => this.toggleState('markInvalidDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="invalid"
        >
          <InvalidRequest
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            invalidReasons={meta.invalidReasons}
            onSubmit={this.requestActionHandler}
            onDismiss={() => this.toggleState('markInvalidDialog')}
          />
        </Dialog>
        <Dialog
          open={acceptPaymentDialog}
          maxWidth="lg"
          onClose={!isAcceptingPayment
            ? () => this.toggleState('acceptPaymentDialog') : undefined}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="accept payment"
        >
          <MarkPayment
            meta={meta}
            request={activeRequest}
            walletBalance={getB2bWalletBalanceResp}
            suggestions={suggestions}
            getSuggestions={this.getSuggestions}
            expert={expert}
            paymentType="ACCEPT"
            loading={isAcceptingPayment}
            onPaymentMark={this.acceptPaymentHandler}
            onDismiss={() => this.toggleState('acceptPaymentDialog')}
          />
        </Dialog>
        <Dialog
          open={refundFromWalletDialog}
          maxWidth="lg"
          onClose={!isRefundingFromWallet
            ? () => this.toggleState('refundFromWalletDialog') : undefined}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="refund payment"
        >
          <MarkPayment
            meta={meta}
            request={activeRequest}
            expert={expert}
            paymentType="REFUND"
            loading={isRefundingFromWallet}
            onPaymentMark={this.refundFromWallet}
            onDismiss={() => this.toggleState('refundFromWalletDialog')}
          />
        </Dialog>
        <Dialog
          open={refundPaymentDialog}
          maxWidth="lg"
          onClose={!isRefundingPayment
            ? () => this.toggleState('refundPaymentDialog') : undefined}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="refund payment"
        >
          <MarkPayment
            meta={meta}
            request={activeRequest}
            expert={expert}
            paymentType="REFUND"
            loading={isRefundingPayment}
            onPaymentMark={this.refundPaymentHandler}
            onDismiss={() => this.toggleState('refundPaymentDialog')}
          />
        </Dialog>
        <Dialog
          open={newRefundPaymentDialog}
          maxWidth="lg"
          onClose={!isRefundingToWallet
            ? () => this.toggleState('newRefundPaymentDialog') : undefined}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="refund payment"
        >
          <MarkPayment
            meta={meta}
            request={activeRequest}
            expert={expert}
            paymentType="NEW_REFUND"
            loading={isRefundingToWallet}
            onPaymentMark={this.handlePaymentRecord}
            onDismiss={() => this.toggleState('newRefundPaymentDialog')}
          />
        </Dialog>
        <Dialog
          open={orderModificationDialog}
          maxWidth="lg"
          onClose={() => this.toggleState('orderModificationDialog')}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="order modification"
        >
          <OrderModification
            meta={meta}
            request={activeRequest}
            expert={expert}
            loading={isUpdatingStatus}
            onInstallmentsUpdate={this.onInstallmentsUpdate}
            onDismiss={() => this.toggleState('orderModificationDialog')}
          />
        </Dialog>
        <Dialog
          open={sendItineraryDialog}
          maxWidth="lg"
          onClose={() => this.toggleState('sendItineraryDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="send itinerary"
        >
          <SendItinerary
            isUpdatingStatus={isUpdatingStatus}
            onSubmit={this.sendItinerary}
            onDismiss={() => this.toggleState('sendItineraryDialog')}
          />
        </Dialog>
        <Dialog
          open={cancelRequestDialog}
          maxWidth="lg"
          onClose={() => this.toggleState('cancelRequestDialog')}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="cancel request"
        >
          <CancelBooking
            cancellationType="REQUEST"
            request={activeRequest}
            requestCancellationReasons={meta.requestCancellationReasons || []}
            isLoading={isUpdatingStatus}
            cancelBooking={this.requestActionHandler}
            onDismiss={() => this.toggleState('cancelRequestDialog')}
          />
        </Dialog>
        <Dialog
          open={confirmRequirementDialog}
          maxWidth="lg"
          onClose={isSavingItineraryDraft ? undefined : () => this.toggleState('confirmRequirementDialog')}
          classes={{
            paper: classes.visiblePaper,
          }}
          aria-labelledby="create requirement"
        >
          <ConfirmRequirement
            requirement={(activeRequest || {}).requirement || {}}
            request={activeRequest}
            onSubmit={this.updateRequest}
            meta={meta}
            isLoading={isSavingItineraryDraft}
            suggestions={suggestions}
            isFetchingSuggestions={isFetchingSuggestions}
            getSuggestions={this.getSuggestions}
            onDismiss={() => this.toggleState('confirmRequirementDialog')}
          />
        </Dialog>
        <Dialog
          open={cancelItineraryDialog}
          maxWidth="lg"
          onClose={() => this.toggleState('cancelItineraryDialog')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="cancel request"
        >
          <CancelBooking
            cancellationType="ITINERARY"
            request={activeRequest}
            itinerary={this.activeItinerary}
            requestCancellationReasons={meta.requestCancellationReasons || []}
            isLoading={isCancellingItinerary}
            cancelBooking={this.cancelItineraryHandler}
            onDismiss={() => this.toggleState('cancelItineraryDialog')}
          />
        </Dialog>
        <Dialog
          open={loaderDialog}
          classes={{
            paper: classes.paper,
          }}
          maxWidth="lg"
        >
          <Loader />
        </Dialog>
        {this.mode === 'dashboard' ? (
          <div className={classes.toolbar}>
            {/*
            <Button
              className={clsx(classes.tabButton, activeTab === 'attention'
                && classes.tabButtonActive)}
              classes={{
                label: classes.buttonLabel,
              }}
              onClick={() => this.tabChangeHandler('attention')}
            >
              {`Needs attention ${displayCount ? `- ${attention}` : ''}`}
            </Button>
            <Button
              className={clsx(classes.tabButton, activeTab === 'live' && classes.tabButtonActive)}
              classes={{
                label: classes.buttonLabel,
              }}
              onClick={() => this.tabChangeHandler('live')}
            >
              {`Live ${displayCount ? `- ${live}` : ''}`}
            </Button>
          */}
            <div className={classes.search}>
              <img src={search} alt="s" className={classes.searchIcon} />
              <InputBase
                classes={{ input: classes.input }}
                className={classes.searchInput}
                placeholder="Search by user name, user id, number, email"
                inputProps={{ 'aria-label': 'search lead' }}
                value={searchQuery}
                onChange={(e) => this.onSearch(e.target.value)}
                endAdornment={(
                  <IconButton
                    onClick={this.clearSearchQuery}
                    className={classes.searchCloseBtn}
                  >
                    <CloseIcon className={classes.searchCloseIcon} />
                  </IconButton>
                )}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <Button
                variant="outlined"
                className={classes.actionButton}
                classes={{
                  label: classes.buttonLabel,
                }}
                disabled={isFetchingRequests}
                onClick={() => this.actionHandler('refresh')}
              >
                <Refresh
                  className={classes.buttonImg}
                  fill={isFetchingRequests ? colors.border : colors.primary}
                />
                Refresh
              </Button>
              <Button
                variant="outlined"
                className={classes.actionButton}
                classes={{
                  label: classes.buttonLabel,
                }}
                onClick={() => this.actionHandler('requirement')}
              >
                <img src={create} alt="create" className={classes.buttonImg} />
                New request
              </Button>
              <div style={{ display: 'inline-flex' }}>
                <Button
                  variant="outlined"
                  className={classes.actionButton}
                  classes={{
                    label: classes.buttonLabel,
                  }}
                  ref={this.notificationsRef}
                  onClick={() => {
                    this.setState({
                      showNotificationsPopper: !showNotificationsPopper,
                    }, () => {
                      setTimeout(() => { this.setZIndex(2); }, 10);
                    });
                  }}
                >
                  <NotificationsIcon className={clsx(classes.buttonImg, classes.primaryColor)} />
                  Notifications
                </Button>
                <Popper
                  open={showNotificationsPopper}
                  anchorEl={this.notificationsRef && this.notificationsRef.current}
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      timeout={0.4}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <div>
                        <ClickAwayListener
                          onClickAway={this.handleClose}
                        >
                          <div>
                            <Notifications />
                          </div>
                        </ClickAwayListener>
                      </div>
                    </Grow>
                  )}
                </Popper>
              </div>
              <Button
                variant="outlined"
                className={classes.actionButton}
                classes={{
                  label: classes.buttonLabel,
                }}
                onClick={() => this.actionHandler('filter')}
              >
                <img src={filterImg} alt="filter" className={classes.buttonImg} />
                Filter
              </Button>
            </div>
          </div>
        ) : null}
        {(isFetchingRequests || isSearchingRequest) && <LinearProgress />}
        <div className={classes.body}>
          {(showEmpty && !isFetchingRequests && _.isEmpty(requestsToRender)) ? (
            <EmptyScreen
              title={emptyTitle}
              description={emptyDescription}
              img={emptyImg}
              extraClass={classes.emptyContainer}
            />
          ) : null}
          {(!(isFetchingRequests || isSearchingRequest)
            || requestsToRender.length) && this.mode === 'dashboard' ? (
              <div
                className={classes.requestsWrapper}
                onScroll={(e) => this.requestScrollHandler(e.currentTarget, e.target.scrollTop)}
              >
                {filterCount ? (
                  <div className={classes.filterRow}>
                    <div className={classes.filtersArea}>
                      {renderedFilters}
                    </div>
                    <div className={classes.clearFilterArea}>
                      <ButtonBase
                        disableRipple
                        className={classes.resetFilterBtn}
                        onClick={this.resetFilter}
                      >
                        CLEAR FILTERS
                      </ButtonBase>
                      <Typography
                        className={classes.resultCount}
                      >
                        {`${requestsToRender.length} results`}
                      </Typography>
                    </div>
                  </div>
                ) : null}
                {(this.mode === 'dashboard' && !showEmpty && (!isFetchingRequests || requestsToRender.length)) ? (
                  <div className={classes.requests}>
                    {
                      requestsToRender.map((request, index) => {
                        const { _id } = request;
                        // if (attn > 0 && activeTab === 'live') {
                        //   return null;
                        // }
                        // if (attn < 0 && activeTab === 'attention') {
                        //   return null;
                        // }
                        return (
                          <RequestCard
                            key={_id}
                            index={index}
                            request={request}
                            expert={expert}
                            canReAssign={this.allowedReassignment}
                            selected={_id === activeRequest?._id}
                            isCardSelected={Boolean(requestSelections[_id])}
                            cardActionHandler={this.cardActionHandler}
                            meta={meta}
                          />
                        );
                      })
                    }
                  </div>
                ) : null}
              </div>
            ) : null}
          {(isFetchingRequests && requestsToRender.length === 0) ? (
            <div className={classes.wrapper}>
              <Loader />
            </div>
          ) : null}
          {/* eslint-disable-next-line no-nested-ternary */}
          {(!showEmpty && (!isFetchingRequests || requestsToRender.length
            || (this.mode === 'focused' && requestList.length))) ? (
              isGettingActiveRequest
                ? (
                  <div
                    style={{
                      width: '60%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <RequestDetails
                    mode={this.mode}
                    renderLength={requestsToRender?.length || 0}
                    request={activeRequest || {}}
                    filtration={roles.includes(ROLES.FILTRATION)}
                    activeTab={activeTab}
                    token={token}
                    expert={expert}
                    requestStatus={meta.requestStatus}
                    getBookings={this.getBookings}
                    bookings={bookings}
                    isGettingBookings={isGettingBookings}
                    paymentMode={meta.paymentMode}
                    onItineraryAction={this.itineraryActionRequestHandler}
                    sendItinerary={this.sendSelectedItinerary}
                    onBookingAction={this.bookingActionHandler}
                    onOrderUpdateRequest={this.orderUpdateRequestHandler}
                    actionHandler={(action, value) => this.tripActionHandler(action, value)}
                    onRemarkUpdate={this.remarkUpdate}
                    isResendingConfirmation={isResendingConfirmation}
                    getRefundData={this.getRefundData}
                    refundData={refundData}
                    getVirtualAccountData={this.getVirtualAccountData}
                    virtualAccountData={virtualAccountData}
                    showSnackbar={showSnackbar}
                  />
                )
            ) : null}
        </div>
        {(Object.keys(requestSelections).length) ? (
          <div className={classes.assignmentBox}>
            <IconButton
              className={classes.dismissIcon}
              onClick={this.resetAssignments}
            >
              <Close />
            </IconButton>
            <Autocomplete
              label="EXPERTS"
              value={ownerVal}
              inputClass={classes.leadInput}
              popupClass={classes.ownerPopupClass}
              data={filteredExperts}
              accessor="fullName"
              secondaryAccessor="type"
              id="_id"
              onChange={(val) => this.handleChange('ownerVal', val)}
              onSelected={(item) => this.handleSelection('fullName', 'ownerVal', item)}
            />
            <Button
              className={classes.assignBtn}
              onClick={this.assignRequestsHandler}
            >
              Assign
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

/**
 * @param {import('@material-ui/core/styles/createMuiTheme').Theme} theme
 */
const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 58px)',
    backgroundColor: theme.colors.background,
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toolbar: {
    minHeight: 48,
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 40px',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.white,
    zIndex: 1,
    boxShadow: '1px 2px 10px 1px rgba(0,0,0,0.1)',
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  emptyContainer: {
    position: 'absolute',
    height: 'calc(100% - 176px)',
    marginTop: 64,
  },
  requestsWrapper: {
    flex: 4,
    marginLeft: 40,
    minWidth: '40%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    paddingBottom: 20,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
      '-ms-overflow-style': 'none',
      width: 0,
      height: 0,
    },
  },
  requests: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // overflowY: 'auto',
    // marginLeft: 40,
    // paddingBottom: 20,
    // scrollbarWidth: 'none',
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    //   '-ms-overflow-style': 'none',
    //   width: 0,
    //   height: 0,
    // },
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 30px 20px 0',
  },
  filtersArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  clearFilterArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  resetFilterBtn: {
    marginBottom: 5,
    width: 102,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    color: theme.colors.primary,
  },
  resultCount: {
    fontSize: 12,
    color: theme.colors.textLight,
  },
  filterChip: {
    borderRadius: 15,
    backgroundColor: theme.colors.white,
    padding: '5px 8px',
    margin: '0 10px 10px 0',
    border: `1px solid ${theme.colors.border}`,
  },
  chipLabel: {
    padding: '0 4px',
  },
  closeIcon: {
    color: theme.colors.primaryBackground,
    height: 16,
    width: 16,
    margin: 0,
  },
  tabButton: {
    backgroundColor: theme.colors.underline_1,
    color: theme.colors.black,
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 0.5,
    borderRadius: 20,
    marginRight: 20,
    padding: '5px 24px',
    minHeight: 36,
    height: 36,
    minWidth: 140,
    transition: 'width 0.8s ease-in-out',
  },
  tabButtonActive: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  buttonLabel: {
    height: 22,
  },
  search: {
    flex: 1,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 600,
    transition: 'width 0.4s ease-in-out',
    '&:focus': {
      width: '100%',
    },
    '&:focus $tabButtonActive, &:focus $tabButton': {
      width: 0,
      minWidth: 0,
      padding: 0,
    },
  },
  paymentLinkContainer: {
    minWidth: 500,
    height: 100,
    margin: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
  },
  searchInput: {
    width: '100%',
    '&:focus-within $searchCloseBtn': {
      display: 'block',
    },
  },
  paymentAmountInput: {
    width: '200px',
    '&:focus-within $searchCloseBtn': {
      display: 'block',
    },
  },
  iconButton: {
    padding: 0,
    width: 32,
    height: 32,
  },
  searchIcon: {
    width: 16,
    height: 16,
    padding: 0,
    margin: 0,
  },
  actionButton: {
    textTransform: 'none',
    borderRadius: 17.5,
    fontSize: 12,
    letterSpacing: 0.5,
    color: theme.colors.black,
    marginRight: 20,
    minHeight: 34,
    height: 34,
    border: `1px solid ${theme.colors.primary}`,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  filterButton: {
    textTransform: 'none',
    borderRadius: 17.5,
    fontSize: 12,
    color: theme.colors.black,
    marginRight: 8,
    minHeight: 36,
    height: 36,
    border: `1px solid ${theme.colors.primary}`,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  sendPaymentDetailsButton: {
    textTransform: 'none',
    borderRadius: 17.5,
    fontSize: 12,
    color: theme.colors.primary,
    marginRight: 8,
    minHeight: 36,
    height: 36,
    border: `1px solid ${theme.colors.primary}`,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  sendIcon: {
    width: 12,
    height: 12,
    marginRight: 6,
  },
  searchCloseBtn: {
    display: 'none',
    padding: 4,
    marginRight: 20,
  },
  searchCloseIcon: {
    width: 16,
    height: 16,
    color: theme.colors.textLight,
  },
  paper: {
    borderRadius: 10,
    overflowY: 'visible',
    overflowX: 'hidden',
  },
  visiblePaper: {
    borderRadius: 10,
    overflowY: 'visible',
  },
  buttonImg: {
    width: 12,
    height: 12,
    marginRight: 10,
  },
  primaryColor: {
    color: theme.colors.primary,
  },
  assignmentBox: {
    backgroundColor: theme.colors.white,
    position: 'fixed',
    bottom: 64,
    right: 64,
    padding: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '0 4px 8px 1px rgba(0,0,0,0.1)',
  },
  ownerPopupClass: {
    bottom: 41,
    top: 'unset',
  },
  leadInput: {
    padding: '6px 5px 6px 15px',
    height: '100%',
    width: '100%',
  },
  dismissIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 4,
  },
  assignBtn: {
    alignSelf: 'flex-end',
    marginLeft: 10,
    padding: '5px 10px',
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBackground,
    borderRadius: 25,
    fontSize: 12,
    letterSpacing: 0.5,
    fontWeight: 'bold',
    height: 32,
    width: 72,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  copyPaymentLinkButton: {
    color: theme.colors.primary,
    fontSize: 12,
    letterSpacing: 0.5,
    marginTop: 28,
  },
  copyIcon: {
    width: 16,
    height: 16,
    marginRight: 6,
    color: theme.colors.primary,
  },
});

Dashboard.propTypes = {
  classes: PropTypes.object,
  getRequestList: PropTypes.func,
  requestSearch: PropTypes.func,
  expert: PropTypes.object,
  token: PropTypes.string,
  requestList: PropTypes.array,
  isFetchingRequests: PropTypes.bool,
  requestListError: PropTypes.bool,
  requestListErrorMsg: PropTypes.string,
  requestSearchError: PropTypes.bool,
  requestSearchErrorMsg: PropTypes.string,
  suggestion: PropTypes.object,
  getAutocompleteSuggestion: PropTypes.func,
  getConversion: PropTypes.func,
  conversion: PropTypes.object,
  addRequest: PropTypes.func,
  suggestions: PropTypes.object,
  meta: PropTypes.object,
  isAddingRequest: PropTypes.bool,
  addRequestResp: PropTypes.object,
  addRequestError: PropTypes.bool,
  addRequestErrorMsg: PropTypes.string,
  showSnackbar: PropTypes.func,
  updateRequestDetails: PropTypes.func,
  sendItinerary: PropTypes.func,
  updateRequestDetailsResp: PropTypes.object,
  updateRequestDetailsError: PropTypes.bool,
  updateRequestDetailsErrorMsg: PropTypes.string,
  requestSearchResp: PropTypes.array,
  isSearchingRequest: PropTypes.bool,
  reAssignRequest: PropTypes.func,
  reAssignRequestResp: PropTypes.object,
  reAssignRequestError: PropTypes.bool,
  reAssignRequestErrorMsg: PropTypes.string,
  updateStatus: PropTypes.func,
  isUpdatingStatus: PropTypes.bool,
  updateStatusResp: PropTypes.object,
  updateStatusError: PropTypes.bool,
  updateStatusErrorMsg: PropTypes.string,
  saveAsTemplateItinerary: PropTypes.func,
  deleteItinerary: PropTypes.func,
  deleteItineraryResp: PropTypes.object,
  deleteItineraryError: PropTypes.bool,
  deleteItineraryErrorMsg: PropTypes.string,
  resetDashboardResp: PropTypes.func,
  remarkUpdate: PropTypes.func,
  updateRemarkResp: PropTypes.object,
  updateRemarkError: PropTypes.bool,
  updateRemarkErrorMsg: PropTypes.string,
  getRequestFilters: PropTypes.func,
  requestFiltersResp: PropTypes.object,
  getRequestFiltersError: PropTypes.bool,
  getRequestFiltersErrorMsg: PropTypes.string,
  starRequest: PropTypes.func,
  interestedRequest: PropTypes.func,
  updateOrder: PropTypes.func,
  isUpdatingOrder: PropTypes.bool,
  updateOrderResp: PropTypes.object,
  updateOrderError: PropTypes.bool,
  updateOrderErrorMsg: PropTypes.string,
  modifyInstallments: PropTypes.func,
  modifyInstallmentsResp: PropTypes.object,
  modifyInstallmentsError: PropTypes.bool,
  modifyInstallmentsErrorMsg: PropTypes.string,
  bookingAction: PropTypes.func,
  isBooking: PropTypes.bool,
  bookingActionResp: PropTypes.object,
  bookingActionError: PropTypes.bool,
  bookingActionErrorMsg: PropTypes.string,
  acceptPayment: PropTypes.func,
  acceptPaymentResp: PropTypes.object,
  acceptPaymentError: PropTypes.bool,
  acceptPaymentErrorMsg: PropTypes.string,
  getRequestByID: PropTypes.func,
  isAcceptingPayment: PropTypes.bool,
  resendConfirmation: PropTypes.func,
  isResendingConfirmation: PropTypes.bool,
  resendConfirmationResp: PropTypes.object,
  resendConfirmationError: PropTypes.bool,
  resendConfirmationErrorMsg: PropTypes.string,
  version: PropTypes.number,
  generateVoucher: PropTypes.func,
  isGeneratingVoucher: PropTypes.bool,
  generateVoucherResp: PropTypes.object,
  generateVoucherError: PropTypes.bool,
  generateVoucherErrorMsg: PropTypes.string,
  refundPayment: PropTypes.func,
  isRefundingPayment: PropTypes.bool,
  refundPaymentResp: PropTypes.object,
  refundPaymentError: PropTypes.bool,
  refundPaymentErrorMsg: PropTypes.string,
  cancelItinerary: PropTypes.func,
  isCancellingItinerary: PropTypes.bool,
  cancelItineraryResp: PropTypes.object,
  cancelItineraryError: PropTypes.bool,
  cancelItineraryErrorMsg: PropTypes.string,
  refundFromWallet: PropTypes.func.isRequired,
  isRefundingFromWallet: PropTypes.bool.isRequired,
  refundFromWalletResp: PropTypes.object.isRequired,
  refundFromWalletError: PropTypes.bool.isRequired,
  refundFromWalletErrorMsg: PropTypes.string.isRequired,
  refundToWallet: PropTypes.func.isRequired,
  isRefundingToWallet: PropTypes.bool.isRequired,
  refundToWalletResp: PropTypes.object.isRequired,
  refundToWalletError: PropTypes.bool.isRequired,
  refundToWalletErrorMsg: PropTypes.string.isRequired,
  isFetchingSuggestions: PropTypes.bool.isRequired,
  saveItineraryDraft: PropTypes.func.isRequired,
  isSavingItineraryDraft: PropTypes.bool.isRequired,
  saveItineraryDraftResp: PropTypes.object.isRequired,
  saveItineraryDraftError: PropTypes.bool.isRequired,
  saveItineraryDraftErrorMsg: PropTypes.string.isRequired,
  markBookingTransaction: PropTypes.func.isRequired,
  isMarkingBookingTransaction: PropTypes.bool.isRequired,
  markBookingTransactionResp: PropTypes.object.isRequired,
  markBookingTransactionError: PropTypes.bool.isRequired,
  markBookingTransactionErrorMsg: PropTypes.string.isRequired,
  cancelBooking: PropTypes.func.isRequired,
  isCancellingBooking: PropTypes.bool.isRequired,
  cancelBookingResp: PropTypes.object.isRequired,
  cancelBookingError: PropTypes.bool.isRequired,
  cancelBookingErrorMsg: PropTypes.string.isRequired,
  isGettingBookings: PropTypes.bool.isRequired,
  bookings: PropTypes.object.isRequired,
  getBookingsError: PropTypes.bool.isRequired,
  getBookingsErrorMsg: PropTypes.string.isRequired,
  getBookings: PropTypes.func.isRequired,
  getB2bWalletBalance: PropTypes.func.isRequired,
  isGettingB2bWalletBalance: PropTypes.bool.isRequired,
  getB2bWalletBalanceResp: PropTypes.object.isRequired,
  getB2bWalletBalanceError: PropTypes.bool.isRequired,
  getB2bWalletBalanceErrorMsg: PropTypes.string.isRequired,
  getRefundData: PropTypes.func.isRequired,
  getRefundDataError: PropTypes.bool.isRequired,
  getRefundDataErrorMsg: PropTypes.string.isRequired,
  refundData: PropTypes.object.isRequired,
  getVirtualAccountData: PropTypes.func.isRequired,
  getVirtualAccountDataError: PropTypes.bool.isRequired,
  getVirtualAccountDataErrorMsg: PropTypes.string.isRequired,
  virtualAccountData: PropTypes.object.isRequired,
  balances: PropTypes.object.isRequired,
  requestById: PropTypes.object,
};

export default hot(withStyles(styles)(Dashboard));
